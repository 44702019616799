import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../utils/api';
import Spinner from '../utils/Spinner';
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Modal,
  Typography,
  Box,
  Badge,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useUserStore } from '../stores/UserStore';
import {
  useFetchAccessRequests,
  useFetchVersionUpdate,
} from '../hooks/overview.hooks';
import { useOverviewStore } from '../stores/OverviewStore';
import { useFetchUser } from '../hooks/user.hooks';
import { clearAllData } from '../utils/indexedDB';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';

// Images
//@ts-ignore
import MonthlyImage from '../assets/img/monthly.jpg';
//@ts-ignore
import YearlyImage from '../assets/img/yearly.jpg';
//@ts-ignore
import ProfitLossImage from '../assets/img/profit_loss.jpg';
//@ts-ignore
import TenantStatusImage from '../assets/img/tenant_status.jpg';

const Overview = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null);
  const [currentDate, setCurrentDate] = useState<string>('');
  const [greeting, setGreeting] = useState<string>('');
  const [isSubscriptionActive, setIsSubscriptionActive] =
    useState<boolean>(false);
  const [modalMessages, setModalMessages] = useState<string[]>([]);
  const [hasCheckedVersion, setHasCheckedVersion] = useState(false);
  const errorMessages = new ErrorMessages();

  const {
    data: user,
    isError: isUserError,
    error: userError,
    isLoading: isUserLoading,
  } = useFetchUser();
  const {
    data: accessRequests,
    isError: isAccessError,
    error: accessError,
    isLoading: isAccessLoading,
  } = useFetchAccessRequests();
  const {
    data: versionUpdate,
    isError: isVersionError,
    error: versionError,
    isLoading: isVersionLoading,
  } = useFetchVersionUpdate();
  const {
    hasVersionNotifications,
    setHasVersionNotifications,
    hasRequestNotifications,
    setHasRequestNotifications,
    releaseDate,
    setReleaseDate,
    updateModalOpen,
    setUpdateModalOpen,
  } = useOverviewStore();

  useEffect(() => {
    if (isUserLoading || isAccessLoading || isVersionLoading) {
      <Spinner />;
      return;
    }
  }, [isUserLoading, isAccessLoading, isVersionLoading]);

  useEffect(() => {
    if (isUserError) {
      ErrorHandler.handleError(userError, errorMessages, toast);
    }
    if (isAccessError) {
      ErrorHandler.handleError(accessError, errorMessages, toast);
    }
    if (isVersionError) {
      ErrorHandler.handleError(versionError, errorMessages, toast);
    }
  }, [
    isUserError,
    userError,
    isAccessError,
    accessError,
    isVersionError,
    versionError,
    errorMessages,
    toast,
  ]);

  // Handle fetched user
  useEffect(() => {
    const fetchUser = async () => {
      if (user) {
        const userState = user;
        const state = userState?.IsActive;
        setIsSubscriptionActive(!!state);

        const now = new Date();
        const formattedDate = format(now, 'dd MMM yyyy');
        setCurrentDate(formattedDate);

        setGreeting(
          getGreeting(userState?.Fullname || 'Guest', now.getHours())
        );
        checkUserInformation(userState);

        if (!hasCheckedVersion) {
          setHasCheckedVersion(true);
        }
      }
    };
    fetchUser();
  }, [user, hasCheckedVersion]);

  // Handle fetched notifications
  useEffect(() => {
    if (accessRequests) {
      const hasPendingNotifications = accessRequests.some(
        (notification: any) =>
          notification.Status === 'Pending' || notification.Status === 'Revoked'
      );
      setHasRequestNotifications(hasPendingNotifications);
    }
  }, [accessRequests]);

  // Handle version updates
  useEffect(() => {
    if (versionUpdate) {
      const storedVersion = versionUpdate?.Version;
      const fetchedReleaseDate = new Date(versionUpdate?.ReleaseDate);
      setReleaseDate(fetchedReleaseDate);

      const latestVersion = process.env.REACT_APP_VERSION;
      if (!latestVersion) {
        console.error('REACT_APP_VERSION is not defined');
        return;
      }

      const currentDate = new Date();
      const twoDaysFromNow = new Date(currentDate.getDate() + 2);
      const twoDaysAgo = new Date(currentDate.getDate() - 2);

      if (storedVersion && storedVersion < latestVersion) {
        setUpdateModalOpen(true);
        setHasVersionNotifications(true);
        api
          .UpdateVersion(latestVersion, 'Version update initiated')
          .catch((err) => {
            toast.error('Error updating version: ' + err.message);
          });
      } else if (
        fetchedReleaseDate > twoDaysAgo &&
        fetchedReleaseDate <= twoDaysFromNow
      ) {
        setUpdateModalOpen(true);
        setHasVersionNotifications(true);
      } else {
        setHasVersionNotifications(false);
      }
    }
  }, [versionUpdate]);

  const handleCloseUpdateModal = () => {
    setUpdateModalOpen(false);
    setHasVersionNotifications(false);
  };

  const isVersionNotificationVisible = () => {
    if (!releaseDate) return true;
    const currentDate = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 7);
    return releaseDate > oneWeekAgo;
  };

  const checkUserInformation = (user: any) => {
    const { isMessageDismissed } = useUserStore.getState();

    if (isMessageDismissed) {
      return;
    }

    const messages = [];

    if (!user?.Fullname || !user?.Country) {
      messages.push(t('profile_update_message'));
    }

    if (user?.VerificationExpiryDate) {
      const expiryDate = format(
        new Date(user.VerificationExpiryDate),
        'dd MMM yyyy'
      );
      messages.push(
        t('email_verification_message', {
          email: user.Email,
          expiryDate,
        })
      );
    }

    if (messages.length > 0) {
      setModalMessages(messages);
    }
  };

  const getGreeting = (fullname: string, hour: number): string => {
    let greetingMsg = '';
    if (hour >= 5 && hour < 12) {
      greetingMsg = t('good_morning');
    } else if (hour >= 12 && hour < 18) {
      greetingMsg = t('good_afternoon');
    } else {
      greetingMsg = t('good_evening');
    }
    return `${greetingMsg}, ${fullname}`;
  };

  const handleCloseModal = () => {
    useUserStore.getState().setMessageDismissed(true);
    setModalMessages([]);
  };

  const handleNotificationsMenuClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationsMenuClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleNotificationAction = (action: string) => {
    setNotificationAnchorEl(null);
    switch (action) {
      case 'requests':
        navigate('/notifications');
        break;
      case 'versionRelease':
        setUpdateModalOpen(true);
        break;
      default:
        break;
    }
  };

  const handleLanguageMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    handleLanguageMenuClose();
    clearAllData();
    api
      .updateUserLanguage(lng)
      .then(async () => {})
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleAnalysisClick = (route: string) => {
    if (route === '/information') {
      navigate(route);
      return;
    }
    if (!canViewAnalysis) {
      toast.error(t('restrictionCaretaker'));
      return;
    }
    navigate(route);
  };

  const canViewAnalysis =
    user?.UserRoleType === 'Landlord' &&
    user?.SubscriptionType === 'Premium' &&
    isSubscriptionActive;

  return (
    <div className="overview-container">
      {/* Modal for displaying messages */}
      <Modal
        open={modalMessages.length > 0}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            {t('updateProfile')}
          </Typography>
          {modalMessages.map((message, index) => (
            <Typography key={index} sx={{ mt: 2 }}>
              {index + 1}: {message}
            </Typography>
          ))}
          <Button onClick={handleCloseModal} variant="contained" sx={{ mt: 2 }}>
            Do not show again
          </Button>
        </Box>
      </Modal>

      {/* Modal for version update notification */}
      <Modal
        open={updateModalOpen}
        onClose={handleCloseUpdateModal}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="update-modal-title" variant="h6" component="h2">
            {t('new_version_available')} - v{process.env.REACT_APP_VERSION}
          </Typography>
          <Typography sx={{ mt: 2 }}>
            {t(
              'a_new_version_of_the_application_is_now_available_please_visit_the_information_page_for_details'
            )}{' '}
          </Typography>
          <Button
            onClick={handleCloseUpdateModal}
            variant="contained"
            sx={{ mt: 2 }}
          >
            {t('close')}
          </Button>
        </Box>
      </Modal>

      <nav className="navbar">
        <div className="search-bar">{greeting}</div>
        <div className="navbar-actions">
          {/* Language selection button */}
          <Tooltip title={t('select_language')} placement="bottom">
            <IconButton
              className="language-button"
              onClick={handleLanguageMenuClick}
              style={{ color: 'black' }}
            >
              <LanguageIcon />
            </IconButton>
          </Tooltip>
          {/* Language menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleLanguageMenuClose}
          >
            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            <MenuItem onClick={() => changeLanguage('fr')}>Français</MenuItem>
          </Menu>

          {/* Notifications dropdown */}
          <Tooltip title={t('view_notifications')} placement="bottom">
            <IconButton
              className="notification-button"
              style={{ color: 'black' }}
              onClick={handleNotificationsMenuClick}
            >
              <NotificationsIcon />
              {hasRequestNotifications && (
                <InfoIcon
                  className="info-icon"
                  style={{ color: 'red', marginLeft: '5px' }}
                />
              )}
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={notificationAnchorEl}
            open={Boolean(notificationAnchorEl)}
            onClose={handleNotificationsMenuClose}
          >
            <MenuItem onClick={() => handleNotificationAction('requests')}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {t('new_requests')}
                {hasRequestNotifications && (
                  <Badge color="error" variant="dot" />
                )}
              </div>
            </MenuItem>
            {/* <MenuItem onClick={() => handleNotificationAction('messages')}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {t('new_message_notification')}
                {hasMessages && <Badge color="error" variant="dot" />}
              </div>
            </MenuItem> */}
            <MenuItem
              onClick={() => handleNotificationAction('versionRelease')}
              style={{
                display: isVersionNotificationVisible() ? 'block' : 'none',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {t('new_version_notification')} - v
                {process.env.REACT_APP_VERSION}
                {hasVersionNotifications && (
                  <Badge color="error" variant="dot" />
                )}
              </div>
            </MenuItem>
          </Menu>

          <Button
            className="date-button"
            variant="text"
            style={{ color: 'black', pointerEvents: 'none' }}
            disabled
          >
            {currentDate}
          </Button>
        </div>
      </nav>
      <main className="overview-content">
        {/* Grid container for displaying analysis options */}
        <div
          className={`grid-container ${canViewAnalysis ? 'grid-enabled' : ''}`}
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${canViewAnalysis ? 2 : 1}, 1fr)`,
          }}
        >
          {canViewAnalysis && (
            <>
              {/* Monthly analysis button */}
              <Grid item>
                <Tooltip
                  title={t('view_monthly_analysis')}
                  placement="top"
                  sx={{ fontSize: '1.5rem' }}
                >
                  <Button
                    className="square-option"
                    onClick={() => handleAnalysisClick('/monthly-analysis')}
                    style={{
                      height: '250px',
                      borderRadius: '35px',
                      pointerEvents: 'auto',
                      backgroundImage: `url(${MonthlyImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
                      transition:
                        'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    }}
                  ></Button>
                </Tooltip>
              </Grid>
              {/* Yearly analysis button */}
              <Grid item>
                <Tooltip
                  title={t('view_yearly_analysis')}
                  placement="top"
                  sx={{ fontSize: '1.5rem' }}
                >
                  <Button
                    className="square-option"
                    onClick={() => handleAnalysisClick('/yearly-analysis')}
                    style={{
                      height: '250px',
                      borderRadius: '35px',
                      pointerEvents: 'auto',
                      backgroundImage: `url(${YearlyImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
                      transition:
                        'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    }}
                  ></Button>
                </Tooltip>
              </Grid>
              {/* Profit and loss analysis button */}
              <Grid item>
                <Tooltip
                  title={t('view_profit_loss_analysis')}
                  placement="top"
                  sx={{ fontSize: '1.5rem' }}
                >
                  <Button
                    className="square-option"
                    onClick={() => handleAnalysisClick('/profit-and-loss')}
                    style={{
                      height: '250px',
                      borderRadius: '35px',
                      pointerEvents: 'auto',
                      backgroundImage: `url(${ProfitLossImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
                      transition:
                        'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    }}
                  ></Button>
                </Tooltip>
              </Grid>
            </>
          )}
          {/* Information/Updates */}
          <Grid item>
            <Tooltip
              title={t('view_information_updates')}
              placement="top"
              sx={{ fontSize: '1.5rem' }}
            >
              <Button
                className="square-option"
                onClick={() => handleAnalysisClick('/information')}
                style={{
                  height: canViewAnalysis ? '250px' : '300px',
                  borderRadius: '35px',
                  pointerEvents: 'auto',
                  backgroundImage: `url(${TenantStatusImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.1)',
                  transition:
                    'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                }}
              ></Button>
            </Tooltip>
          </Grid>
        </div>
      </main>
    </div>
  );
};

export default Overview;
