// src/assets/avatars/avatars.js
//@ts-nocheck
import avatar1 from '../assets/avatars/memo_3.png';
import avatar2 from '../assets/avatars/memo_4.png';
import avatar3 from '../assets/avatars/memo_5.png';
import avatar4 from '../assets/avatars/memo_6.png';
import avatar5 from '../assets/avatars/memo_7.png';
import avatar6 from '../assets/avatars/memo_8.png';
import avatar7 from '../assets/avatars/memo_9.png';
import avatar8 from '../assets/avatars/memo_10.png';
import avatar9 from '../assets/avatars/memo_11.png';
import avatar10 from '../assets/avatars/memo_14.png';
import avatar11 from '../assets/avatars/memo_16.png';
import avatar12 from '../assets/avatars/memo_21.png';
import avatar13 from '../assets/avatars/memo_26.png';
import avatar14 from '../assets/avatars/memo_30.png';
import avatar15 from '../assets/avatars/memo_17.png';

const avatars = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar10,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
];

export default avatars;
