import { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Plan, Subscription } from '../utils/interfaces';
import { api } from '../utils/api';
import Spinner from '../utils/Spinner';
import { toast } from 'react-toastify';
import { formatDate } from '../utils/dateUtils';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';
import { useSubscriptions } from '../hooks/subscription.hooks';
import { useFetchConversionRate } from '../hooks/exchangeRate.hooks';
import { useConversionRateStore } from '../stores/ExchangeRateStore';
import { deleteKeyStore } from '../utils/indexedDB';
import { PaystackButton } from 'react-paystack';
import { usePlans } from '../helper/plans';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import { useFetchUser } from '../hooks/user.hooks';

interface FormData {
  SubscriptionType: string;
  Duration: string;
  PromoCode: string;
}

function Plantype() {
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [formData, setFormData] = useState<FormData>({
    SubscriptionType: '',
    Duration: '3 months',
    PromoCode: '',
  });
  const errorMessages = new ErrorMessages();
  const [showSubscriptions, setShowSubscriptions] = useState(true);
  const [usePromoCode, setUsePromoCode] = useState(false);
  const [isPlanSelected, setIsPlanSelected] = useState(false);
  const [reference, setReference] = useState(`REF_${new Date().getTime()}`);
  const planData = usePlans();

  const {
    data: user,
    isError: isUserError,
    error: userError,
    isLoading: isUserLoading,
  } = useFetchUser();
  const {
    isError: isConversionRateError,
    error: conversionRateError,
    isLoading: isConversionRateLoading,
  } = useFetchConversionRate();
  const {
    data: subscriptions,
    isError: isSubscriptionsError,
    error: subscriptionsError,
    isLoading: isSubscriptionsLoading,
    refetch: refetch,
  } = useSubscriptions();
  const { conversionRate } = useConversionRateStore();

  useEffect(() => {
    if (isUserLoading || isConversionRateLoading || isSubscriptionsLoading) {
      <Spinner />;
      return;
    }
  }, [isUserLoading, isConversionRateLoading, isSubscriptionsLoading]);

  useEffect(() => {
    if (isUserError) {
      ErrorHandler.handleError(userError, errorMessages, toast);
    }
    if (isConversionRateError) {
      ErrorHandler.handleError(conversionRateError, errorMessages, toast);
    }
    if (isSubscriptionsError) {
      ErrorHandler.handleError(subscriptionsError, errorMessages, toast);
    }
  }, [
    isUserError,
    userError,
    isConversionRateError,
    conversionRateError,
    isSubscriptionsError,
    subscriptionsError,
    errorMessages,
  ]);

  const handlePlanClick = (plan: Plan) => {
    setSelectedPlan(plan);
    setFormData({ ...formData, SubscriptionType: plan.name });
    setShowSubscriptions(false);
    setIsPlanSelected(true);
  };

  const handleChange = (
    event:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name as string]: value,
    });
  };

  const handlePromoCodeToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setUsePromoCode(event.target.checked);
  };

  const handlePaymentSuccess = async (
    response:
      | {
          reference: string;
          status: string;
          message: string;
          transaction: string;
        }
      | string
  ) => {
    let paymentReference: string;

    if (typeof response === 'string') {
      paymentReference = response;
    } else {
      const { reference, status, message, transaction } = response;
      paymentReference = reference;

      // Check if the payment was successful
      if (status !== 'success' || message !== 'Approved') {
        toast.error('Payment failed or not approved. Please try again.');
        return;
      }

      // Check if transaction reference is valid
      if (!transaction) {
        toast.error('Invalid transaction reference.');
        return;
      }
    }

    // Check for promo code usage
    if (paymentReference === 'promo_code_used') {
      // Promo code used, skip payment
      try {
        const subCreateData: Subscription = {
          SubscriptionType: formData.SubscriptionType,
          Duration: formData.Duration,
          PromoCode: formData.PromoCode,
          PaymentReference: `PromoCode_${new Date().getTime()}`,
        };
        await api.createSubscription(subCreateData);
        await deleteKeyStore('profileDetails');
        toast.success(t('subscription_created'));
        refetch();
        resetForm();
        setShowSubscriptions(true);
      } catch (error: any) {
        ErrorHandler.handleError(error, errorMessages, toast);
      }
    } else {
      // Payment successful
      try {
        const subCreateData: Subscription = {
          SubscriptionType: formData.SubscriptionType,
          Duration: formData.Duration,
          PromoCode: formData.PromoCode,
          PaymentReference: paymentReference,
        };
        await api.createSubscription(subCreateData);
        await deleteKeyStore('profileDetails');
        toast.success(t('subscription_created'));
        refetch();
        resetForm();
        setShowSubscriptions(true);
      } catch (error: any) {
        ErrorHandler.handleError(error, errorMessages, toast);
      }
    }

    setReference(`REF_${new Date().getTime()}`);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (usePromoCode && !formData.PromoCode) {
      toast.error(t('no_promo_code'));
      return;
    }

    if (!selectedPlan) {
      toast.error(t('select_plan'));
      return;
    }

    if (usePromoCode && formData.PromoCode) {
      // Promo code used, handle subscription directly
      await handlePaymentSuccess('promo_code_used');
    }
  };

  const resetForm = () => {
    setSelectedPlan(null);
    setFormData({
      SubscriptionType: '',
      Duration: '3 months',
      PromoCode: '',
    });
    setIsPlanSelected(false);
  };

  const handleCancel = () => {
    resetForm();
    setShowSubscriptions(true);
  };

  const handleClose = () => {
    toast.info(t('payment_not_completed'));
    setReference(`REF_${new Date().getTime()}`);
  };

  const calculatePaystackAmount = (
    price: string,
    duration: string,
    conversionRate: string
  ): number => {
    const priceInUSD = parseFloat(price.replace(/[^0-9.]/g, ''));
    const months = parseInt(duration.split(' ')[0], 10);
    const rate = parseFloat(conversionRate);
    const amountInKobo = priceInUSD * months * rate * 100;
    return Math.floor(amountInKobo);
  };

  const componentProps = {
    email: user?.Email || '',
    amount: selectedPlan
      ? calculatePaystackAmount(
          selectedPlan.price,
          formData.Duration,
          conversionRate as string
        )
      : 0,
    reference: reference,
    currency: 'NGN', // Currency for the payment
    text: t('pay_now'),
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || '',
    metadata: {
      custom_fields: [
        {
          display_name: 'Customer Name',
          variable_name: 'customer_name',
          value: user?.Fullname || user?.Username,
        },
        {
          display_name: 'Subscription Type',
          variable_name: 'subscription_type',
          value: formData.SubscriptionType,
        },
        {
          display_name: 'Duration',
          variable_name: 'duration',
          value: formData.Duration,
        },
        {
          display_name: 'Price',
          variable_name: 'price',
          value: selectedPlan?.price,
        },
        {
          display_name: 'Exchange Rate',
          variable_name: 'exchange_rate',
          value: conversionRate,
        },
        {
          display_name: 'Country',
          variable_name: 'country',
          value: user?.Country || 'Not provided',
        },
        {
          display_name: 'City',
          variable_name: 'city',
          value: user?.City || 'Not provided',
        },
      ],
    },
    onSuccess: handlePaymentSuccess,
    onClose: handleClose,
  };

  return (
    <div className="overview-container">
      <main className="plantype-content">
        <div className="subscription-container">
          {!isPlanSelected && showSubscriptions && (
            <div>
              <Typography variant="h1">{t('pickYourPlan')}</Typography>
              <Box className="plans">
                {planData.map((plan: Plan) => (
                  <Box
                    className={`plan ${plan.styleClass}`}
                    key={plan.name}
                    onClick={() => handlePlanClick(plan)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Box className={`plan-header ${plan.styleClass}`}>
                      <Typography variant="h2">{t(plan.name)}</Typography>
                      <Typography variant="body1" className="price">
                        {`${plan.price} / ${plan.monthly}`}
                      </Typography>
                      {plan.name === 'Standard' && (
                        <Typography
                          variant="body2"
                          className="recommended-badge"
                        >
                          {t('recommended')}
                        </Typography>
                      )}
                    </Box>
                    <ul className="plan-features">
                      {plan.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                    <Button
                      variant="contained"
                      className={`get-started ${plan.styleClass}`}
                    >
                      {t('get_started')}
                    </Button>
                  </Box>
                ))}
              </Box>
            </div>
          )}

          {isPlanSelected && selectedPlan && (
            <Box
              component="form"
              onSubmit={handleSubmit}
              className="file-upload-form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Typography variant="h2">
                  {t('selectedPlan')}: {selectedPlan.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  mb: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <FormControl sx={{ mb: 2 }}>
                  <InputLabel>{t('duration')}</InputLabel>
                  <Select
                    name="Duration"
                    value={formData.Duration}
                    onChange={handleChange}
                    sx={{ width: '300px' }}
                  >
                    <MenuItem value="3 months">{t('3_months')}</MenuItem>
                    <MenuItem value="6 months">{t('6_months')}</MenuItem>
                    <MenuItem value="12 months">{t('12_months')}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl component="fieldset" sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={usePromoCode}
                        onChange={handlePromoCodeToggle}
                      />
                    }
                    label={t('use_promo_code')}
                  />
                </FormControl>
                {usePromoCode && (
                  <TextField
                    name="PromoCode"
                    label={t('promo_code')}
                    value={formData.PromoCode}
                    onChange={handleChange}
                    sx={{ width: '300px', mt: 2 }}
                  />
                )}
              </Box>
              <Box sx={{ mt: 2 }}>
                {usePromoCode ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      height: '40px',
                      width: '80px',
                      padding: '8px 16px',
                      borderRadius: '4px',
                    }}
                  >
                    {t('apply_now')}
                  </Button>
                ) : (
                  <div style={{ display: 'flex', gap: '8px' }}>
                    {isPlanSelected && (
                      <PaystackButton
                        {...componentProps}
                        className="paystack-button"
                      />
                    )}

                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleCancel}
                      sx={{
                        backgroundColor: '#d32f2f',
                        color: '#fff',
                        height: '40px',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        '&:hover': {
                          backgroundColor: '#a00000',
                        },
                      }}
                    >
                      {t('cancel')}
                    </Button>
                  </div>
                )}
              </Box>
            </Box>
          )}

          {!isPlanSelected && showSubscriptions && subscriptions && (
            <Box className="subscription-table">
              {subscriptions.some((sub) => sub.SubscriptionType !== 'Free') && (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>{t('current_subscription')}</th>
                        <th>{t('valid_till')}</th>
                        <th>{t('status')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptions.map(
                        (sub) =>
                          sub.SubscriptionType !== 'Free' && (
                            <tr key={sub.SubscriptionID}>
                              <td>{t(sub.SubscriptionType)}</td>
                              <td>
                                {sub.EndDate ? formatDate(sub.EndDate) : 'N/A'}
                              </td>

                              <td>
                                {sub.IsActive ? t('active') : t('not_active')}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </Box>
          )}
        </div>
      </main>
    </div>
  );
}

export default Plantype;
