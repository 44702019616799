import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../utils/api';
import { ApiResponse, Token } from '../utils/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function Subscription() {
  const { token } = useParams<Token>();
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<string>('failed');
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      api
        .verifySubscriptionLink(token)
        .then((response: ApiResponse) => {
          if (response.success) {
            setSubscriptionStatus('success');
            setIsSuccess(true);
          } else {
            setSubscriptionStatus('failed');
            setIsSuccess(false);
          }
        })
        .catch(() => {
          setSubscriptionStatus('failed');
          setIsSuccess(false);
        });
    } else {
      setSubscriptionStatus('invalid_token');
      setIsSuccess(false);
    }
  }, [token]);

  return (
    <div
      className="verification-container"
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
      }}
    >
      <div className="content">
        <h2 className="activated-text">{t('subscription_text.title')}</h2>
        {isSuccess === true && (
          <div className="success-message">
            <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
            <p className="success-text">
              {t(`subscription_text.${subscriptionStatus}`)}
            </p>
          </div>
        )}
        {isSuccess === false && (
          <div className="failure-message">
            <FontAwesomeIcon icon={faTimesCircle} className="failure-icon" />
            <p>{t(`subscription_text.${subscriptionStatus}`)}</p>
          </div>
        )}
        {isSuccess === null && (
          <p>{t(`subscription_text.${subscriptionStatus}`)}</p>
        )}
      </div>
    </div>
  );
}

export default Subscription;
