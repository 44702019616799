import { useState } from 'react';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import { useTranslation } from 'react-i18next';
import Spinner from '../utils/Spinner';
import {
  Box,
  Button,
  Container,
  Tooltip,
  Grid,
  TextField,
  Typography,
  Modal,
} from '@mui/material';
import TermsConditionsModal from '../helper/TermsConditionsModal';
import PrivacyPolicyModal from '../helper/PrivacyPolicyModal';
import Updates from '../helper/Updates';

const Information = () => {
  const { t } = useTranslation();
  const errorMessages = new ErrorMessages();
  const currentYear = new Date().getFullYear();
  const [showUpdatesModal, setShowUpdatesModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [messageSubject, setMessageSubject] = useState('');
  const [messageDescription, setMessageDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const disabledCategories = ['partners', 'affiliate'];

  const handleOpenModal = (modalType: 'news' | 'contact') => {
    if (modalType === 'news') {
      setShowUpdatesModal(true);
    } else if (modalType === 'contact') {
      setShowContactModal(true);
    }
  };

  const handleCloseModals = () => {
    setShowUpdatesModal(false);
    setShowContactModal(false);
    setLoading(false);
  };

  const handleSendMessage = () => {
    setLoading(true);

    if (!messageSubject || !messageDescription) {
      setLoading(false);
      toast.error(t('fieldsEmpty'));
      return;
    }

    const messageData = {
      Subject: messageSubject,
      Description: messageDescription,
    };

    api
      .contactUsEmail(messageData)
      .then(() => {
        setLoading(false);
        setShowContactModal(false);
        toast.success(t('message_sent'));
      })
      .catch((error) => {
        setLoading(false);
        ErrorHandler.handleError(error, errorMessages, toast);
      });
  };

  return (
    <div className="overview-container">
      <main className="information-content">
        <Box sx={{ padding: '20px' }}>
          <Container maxWidth="lg">
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{ color: '#000' }}
            >
              {t('categories')}
            </Typography>
            <Grid container spacing={3}>
              {['partners', 'news', 'affiliate'].map((category, index) => {
                const isDisabled = disabledCategories.includes(category);
                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Tooltip
                      title={
                        category === 'news'
                          ? t('Latest Changes')
                          : 'Coming soon'
                      }
                      arrow
                      disableHoverListener={
                        !(category === 'news' || isDisabled)
                      }
                    >
                      <Box
                        sx={{
                          backgroundColor: isDisabled ? '#ccc' : '#4D9AD3',
                          color: isDisabled ? '#777' : '#fff',
                          padding: '20px',
                          borderRadius: '8px',
                          textAlign: 'center',
                          transition: 'transform 0.3s',
                          '&:hover': {
                            transform: isDisabled ? 'none' : 'scale(1.05)',
                          },
                        }}
                      >
                        <Typography variant="h6" component="h2">
                          {t(category)}
                        </Typography>
                        <Button
                          variant="contained"
                          sx={{ marginTop: '10px' }}
                          disabled={isDisabled}
                          onClick={() => handleOpenModal('news')}
                        >
                          {t('view')}
                        </Button>
                      </Box>
                    </Tooltip>
                  </Grid>
                );
              })}
            </Grid>

            <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
              <Button
                variant="outlined"
                sx={{ color: '#000', borderColor: '#000' }}
                onClick={() => handleOpenModal('contact')}
              >
                {t('contact_us')}
              </Button>
            </Box>

            {/* Modal for Updates */}
            <Modal
              open={showUpdatesModal}
              onClose={handleCloseModals}
              aria-labelledby="updates-modal"
              aria-describedby="updates-modal-description"
              sx={{ backdrop: { backdropFilter: 'blur(4px)' } }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '90%',
                  maxWidth: '800px',
                  bgcolor: '#fff',
                  boxShadow: 24,
                  p: 4,
                  overflowY: 'auto',
                }}
              >
                <Updates /> {/* Render Updates component */}
              </Box>
            </Modal>

            {/* Modal for Contact Us */}
            <Modal
              open={showContactModal}
              onClose={handleCloseModals}
              aria-labelledby="contact-us-modal"
              aria-describedby="contact-us-modal-description"
              sx={{ backdrop: { backdropFilter: 'blur(4px)' } }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '90%',
                  maxWidth: '400px',
                  bgcolor: '#fff',
                  boxShadow: 24,
                  p: 4,
                  overflowY: 'auto',
                }}
              >
                <Typography variant="h6" component="h2">
                  {t('contact_us')}
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  id="message-subject"
                  label={t('subject')}
                  value={messageSubject}
                  onChange={(e) => setMessageSubject(e.target.value)}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  id="message-description"
                  label={t('description')}
                  multiline
                  rows={3}
                  value={messageDescription}
                  onChange={(e) => setMessageDescription(e.target.value)}
                />
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCloseModals}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendMessage}
                  >
                    {t('send')}
                  </Button>
                </Box>
              </Box>
            </Modal>

            {/* Spinner component */}
            {loading && (
              <Box
                sx={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 9999,
                }}
              >
                <Spinner />
              </Box>
            )}

            {/* Footer section */}
            <Box
              sx={{
                marginTop: '40px',
                textAlign: 'center',
                fontSize: '12px',
                color: '#000',
              }}
            >
              <Box sx={{ marginTop: '20px' }}>
                <TermsConditionsModal />
                <PrivacyPolicyModal />
              </Box>
              <Typography
                variant="body2"
                sx={{ color: '#000', marginTop: '20px', fontSize: '12px' }}
              >
                {t('copyright')} © {currentYear} | {t('powered_by')} <br />
                {t('rightreserved')}
              </Typography>
            </Box>
          </Container>
        </Box>
      </main>
    </div>
  );
};

export default Information;
