export interface Country {
  name: string;
  currency: string;
  code?: string;
}

const currencySymbols: { [key: string]: string } = {
  NGN: '₦',
  ETB: 'Br',
  EGP: '£',
  CDF: 'FC',
  ZAR: 'R',
  TZS: 'TSh',
  KES: 'KSh',
  UGX: 'USh',
  DZD: 'د.ج',
  SDG: 'ج.س.',
  MAD: 'د.م.',
  AOA: 'Kz',
  MZN: 'MT',
  GHS: '₵',
  MGA: 'Ar',
  XAF: 'FCFA',
  XOF: 'CFA',
  MWK: 'MK',
  ZMW: 'ZK',
  SOS: 'Sh',
  ZWL: '$',
  GNF: 'FG',
  RWF: 'FRw',
  BIF: 'FBu',
  TND: 'د.ت',
  SSP: '£',
  SLL: 'Le',
  LYD: 'ل.د',
  LRD: 'L$',
  MRU: 'UM',
  ERN: 'Nfk',
  NAD: 'N$',
  GMD: 'D',
  BWP: 'P',
  LSL: 'L',
  MUR: '₨',
  SZL: 'E',
  DJF: 'Fdj',
  KMF: 'CF',
  CVE: '$',
  STN: 'Db',
  SCR: '₨',
};

const currencyCodes: { [key: string]: string } = {
  '₦': 'NGN',
  Br: 'ETB',
  '£EGP': 'EGP', // Distinguish using concatenation of the symbol and currency code
  FC: 'CDF',
  R: 'ZAR',
  TSh: 'TZS',
  KSh: 'KES',
  USh: 'UGX',
  'د.ج': 'DZD',
  'ج.س.': 'SDG',
  'د.م.': 'MAD',
  Kz: 'AOA',
  MT: 'MZN',
  '₵': 'GHS',
  Ar: 'MGA',
  FCFA: 'XAF',
  CFA: 'XOF',
  MK: 'MWK',
  ZK: 'ZMW',
  Sh: 'SOS',
  $ZWL: 'ZWL', // Distinguish different currencies using $
  FG: 'GNF',
  FRw: 'RWF',
  FBu: 'BIF',
  'د.ت': 'TND',
  '£SSP': 'SSP',
  Le: 'SLL',
  'ل.د': 'LYD',
  L$: 'LRD',
  UM: 'MRU',
  Nfk: 'ERN',
  N$: 'NAD',
  D: 'GMD',
  P: 'BWP',
  L: 'LSL',
  '₨MUR': 'MUR', // Distinguish using ₨MUR and ₨SCR
  E: 'SZL',
  Fdj: 'DJF',
  CF: 'KMF',
  $CVE: 'CVE', // Distinguish multiple $ symbols with respective codes
  Db: 'STN',
  '₨SCR': 'SCR',
};

export const countries: Country[] = [
  { name: 'Nigeria', currency: currencySymbols.NGN },
  { name: 'Ethiopia', currency: currencySymbols.ETB },
  { name: 'Egypt', currency: currencySymbols.EGP },
  { name: 'Democratic Republic of the Congo', currency: currencySymbols.CDF },
  { name: 'South Africa', currency: currencySymbols.ZAR },
  { name: 'Tanzania', currency: currencySymbols.TZS },
  { name: 'Kenya', currency: currencySymbols.KES },
  { name: 'Uganda', currency: currencySymbols.UGX },
  { name: 'Algeria', currency: currencySymbols.DZD },
  { name: 'Sudan', currency: currencySymbols.SDG },
  { name: 'Morocco', currency: currencySymbols.MAD },
  { name: 'Angola', currency: currencySymbols.AOA },
  { name: 'Mozambique', currency: currencySymbols.MZN },
  { name: 'Ghana', currency: currencySymbols.GHS },
  { name: 'Madagascar', currency: currencySymbols.MGA },
  { name: 'Cameroon', currency: currencySymbols.XAF },
  { name: "Côte d'Ivoire", currency: currencySymbols.XOF },
  { name: 'Niger', currency: currencySymbols.XOF },
  { name: 'Burkina Faso', currency: currencySymbols.XOF },
  { name: 'Mali', currency: currencySymbols.XOF },
  { name: 'Malawi', currency: currencySymbols.MWK },
  { name: 'Zambia', currency: currencySymbols.ZMW },
  { name: 'Senegal', currency: currencySymbols.XOF },
  { name: 'Chad', currency: currencySymbols.XAF },
  { name: 'Somalia', currency: currencySymbols.SOS },
  { name: 'Zimbabwe', currency: currencySymbols.ZWL },
  { name: 'Guinea', currency: currencySymbols.GNF },
  { name: 'Rwanda', currency: currencySymbols.RWF },
  { name: 'Benin', currency: currencySymbols.XOF },
  { name: 'Burundi', currency: currencySymbols.BIF },
  { name: 'Tunisia', currency: currencySymbols.TND },
  { name: 'South Sudan', currency: currencySymbols.SSP },
  { name: 'Togo', currency: currencySymbols.XOF },
  { name: 'Sierra Leone', currency: currencySymbols.SLL },
  { name: 'Libya', currency: currencySymbols.LYD },
  { name: 'Congo', currency: currencySymbols.XAF },
  { name: 'Liberia', currency: currencySymbols.LRD },
  { name: 'Central African Republic', currency: currencySymbols.XAF },
  { name: 'Mauritania', currency: currencySymbols.MRU },
  { name: 'Eritrea', currency: currencySymbols.ERN },
  { name: 'Namibia', currency: currencySymbols.NAD },
  { name: 'Gambia', currency: currencySymbols.GMD },
  { name: 'Botswana', currency: currencySymbols.BWP },
  { name: 'Gabon', currency: currencySymbols.XAF },
  { name: 'Lesotho', currency: currencySymbols.LSL },
  { name: 'Guinea-Bissau', currency: currencySymbols.XOF },
  { name: 'Equatorial Guinea', currency: currencySymbols.XAF },
  { name: 'Mauritius', currency: currencySymbols.MUR },
  { name: 'Eswatini', currency: currencySymbols.SZL },
  { name: 'Djibouti', currency: currencySymbols.DJF },
  { name: 'Comoros', currency: currencySymbols.KMF },
  { name: 'Cabo Verde', currency: currencySymbols.CVE },
  { name: 'Sao Tome and Principe', currency: currencySymbols.STN },
  { name: 'Seychelles', currency: currencySymbols.SCR },
];

// Helper function to get the currency code based on the country name
export const getCurrencyCodeByCountry = (
  countryName: string
): string | undefined => {
  const country = countries.find((c) => c.name === countryName);
  if (country) {
    // Use the currency symbol (or concatenated symbol+code) to get the currency code
    return Object.entries(currencyCodes).find(
      ([symbol]) =>
        symbol === country.currency || symbol.includes(country.currency)
    )?.[1];
  }
  return undefined;
};
