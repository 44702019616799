import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Updates = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: '20px',
        maxHeight: '400px',
        overflowY: 'auto',
      }}
    >
      {/* Title with version number */}
      <Typography variant="h5" component="h2" gutterBottom>
        {t('updates.title')} - v{process.env.REACT_APP_VERSION}
      </Typography>

      <Typography variant="body1" paragraph>
        {t('updates.announcement')}
      </Typography>

      <Typography variant="body1" paragraph>
        {t('updates.interface')}
      </Typography>

      <Typography variant="body1" paragraph>
        {t('updates.languageSupport')}
      </Typography>

      <Typography variant="body1" paragraph>
        {t('updates.subscription')}
      </Typography>

      <Typography variant="body1" paragraph>
        <p>{t('updates.features')}</p>
        {t('updates.register')}
        <br />
        {t('updates.redirect')}
        <br />
        {t('updates.confirmation')}
        <br />
        {t('updates.analytics')}
        <br />
        {t('updates.notifications')}
        <br />
        {t('updates.manageProperties')}
        <br />
        {t('updates.manageMessages')}
        <br />
        {t('updates.freePlan')}
      </Typography>

      <Typography variant="body1" paragraph>
        {t('updates.thankYou')}
      </Typography>
    </Box>
  );
};

export default Updates;
