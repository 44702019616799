import { api, isCustomError } from '../utils/api';
import { getCurrencyCodeByCountry } from '../utils/countries';
import { ExchangeRateResponse } from '../utils/interfaces';
import { useQuery } from '@tanstack/react-query';
import { useConversionRateStore } from '../stores/ExchangeRateStore';
import { toast } from 'react-toastify';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import {
  encryptData,
  saveData,
  getData,
  decryptData,
} from '../utils/indexedDB';
import { useUserStore } from '../stores/UserStore';

const errorMessages = new ErrorMessages();

// Function to fetch and save conversion rate
const fetchAndSaveConversionRate = async (currencyCode: string, set: any) => {
  const rate = await api.getCurrencyConversionRate(currencyCode);
  const encryptedData = encryptData(rate.toString());
  await saveData('conversionRate', encryptedData);

  set(rate.toString()); // Update Zustand store

  const rateData: ExchangeRateResponse = {
    CurrentRate: rate.toString(),
    Currency: 'USD',
    TargetedCurrency: currencyCode,
  };

  await api.saveExchangeRate(rateData);
};

// Function to load conversion rate from IndexedDB
const loadConversionRateFromIndexedDB = async (): Promise<string | null> => {
  try {
    const savedRate = await getData('conversionRate');
    if (savedRate) {
      const decryptedData = decryptData(savedRate.data);
      return decryptedData;
    }
    return null; // Return null if no data is found
  } catch (error) {
    ErrorHandler.handleError(error, errorMessages, toast);
    return null; // Return null on error
  }
};

// Function to fetch conversion rate with fallback to IndexedDB
const fetchConversionRate = async (set: any): Promise<string> => {
  try {
    const { user } = useUserStore.getState();
    const country = user?.Country;

    if (country) {
      const currencyCode = getCurrencyCodeByCountry(country);
      const exchangeRateResponse = await api.getExchangeRate();

      if (exchangeRateResponse && exchangeRateResponse.CurrentRate) {
        const currentRate = exchangeRateResponse.CurrentRate;
        const encryptedData = encryptData(currentRate.toString());
        await saveData('conversionRate', encryptedData);
        set(currentRate.toString()); // Update Zustand store
        return currentRate.toString();
      } else if (currencyCode) {
        await fetchAndSaveConversionRate(currencyCode, set);
        return useConversionRateStore.getState().conversionRate;
      } else {
        toast.error('Currency code not found for the selected country');
      }
    } else {
      console.error('Country code not found for the user');
    }
  } catch (error) {
    if (isCustomError(error)) {
      if (error.statusCode === 400 || error.statusCode === 404) {
        const { user } = useUserStore.getState();
        const country = user?.Country;

        if (country) {
          const currencyCode = getCurrencyCodeByCountry(country);
          if (currencyCode) {
            await fetchAndSaveConversionRate(currencyCode, set);
            return useConversionRateStore.getState().conversionRate;
          } else {
            ErrorHandler.handleError(error, errorMessages, toast);
          }
        } else {
          ErrorHandler.handleError(error, errorMessages, toast);
        }
      }
    } else {
      ErrorHandler.handleError(error, errorMessages, toast);
    }
  }

  return useConversionRateStore.getState().conversionRate;
};

// React Query hook to fetch conversion rate
export const useFetchConversionRate = () => {
  const { setConversionRate } = useConversionRateStore();

  return useQuery<any>({
    queryKey: ['conversionRate'],
    queryFn: async () => {
      const rateFromDB = await loadConversionRateFromIndexedDB();

      if (rateFromDB) {
        setConversionRate(rateFromDB); // Set rate from IndexedDB to Zustand store
        return rateFromDB; // Return exchange rate from IndexedDB
      } else {
        const rateFromApi = await fetchConversionRate(setConversionRate); // Fetch from API if not in IndexedDB
        return rateFromApi;
      }
    },
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
  });
};
