import React from 'react';
import { Modal, Grid, Box } from '@mui/material';

interface AvatarModalProps {
  avatars: string[];
  onSelect: (avatarUrl: string) => void;
  onClose: () => void;
}

const AvatarModal: React.FC<AvatarModalProps> = ({
  avatars,
  onSelect,
  onClose,
}) => {
  return (
    <Modal
      open={true} // Ensure modal is open
      onClose={onClose}
      aria-labelledby="avatar-selection-modal"
      aria-describedby="modal-to-select-avatar"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 400, // Adjust width as needed
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Grid container spacing={2}>
          {avatars.map((avatar, index) => (
            <Grid item key={index}>
              <img
                src={avatar}
                alt={`Avatar ${index}`}
                style={{ width: 100, height: 100, cursor: 'pointer' }}
                onClick={() => onSelect(avatar)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  );
};

export default AvatarModal;
