import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Button,
  TextField,
  IconButton,
  Typography,
  TablePagination,
  Checkbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import { Message } from '../utils/interfaces';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../utils/dateUtils';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import Spinner from '../utils/Spinner';
import { useFetchMessages } from '../hooks/message.hooks';
import { useMessageStore } from '../stores/MessageStore';
import { deleteKeyStore } from '../utils/indexedDB';

function Messages() {
  const { t } = useTranslation();
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const errorMessages = new ErrorMessages();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedMessages, setSelectedMessages] = useState<number[]>([]);

  const { isLoading } = useFetchMessages();

  const {
    isError: isMessageError,
    error: messageError,
    isLoading: isMessageLoading,
    refetch,
  } = useFetchMessages();
  const { messages } = useMessageStore();

  useEffect(() => {
    if (isMessageLoading) {
      <Spinner />;
      return;
    }
  }, [isMessageLoading]);

  useEffect(() => {
    if (isMessageError) {
      ErrorHandler.handleError(messageError, errorMessages, toast);
    }
  }, [isMessageError, messageError]);

  const handleRefetch = () => {
    refetch()
      .then(() => {
        console.log('Data successfully refetched');
      })
      .catch((error) => {
        console.error('Error refetching tenants:', error);
      });
  };

  const handleRowClick = (message: Message) => {
    // Handle row click to show message details
    setSelectedMessage(message);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMessage(null);
  };

  const handleDeleteMessage = (messageID: number) => {
    api
      .deleteMessage(messageID)
      .then(async () => {
        if (messages)
          messages.filter((message) => message.MessageID !== messageID);
        toast.success(t('message_deleted_success'));
        await deleteKeyStore('messages');
        handleRefetch();
      })
      .catch((error) => {
        toast.error(t(error.message));
      });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectMessage = (messageID: number) => {
    setSelectedMessages((prevSelected) =>
      prevSelected.includes(messageID)
        ? prevSelected.filter((id) => id !== messageID)
        : [...prevSelected, messageID]
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allMessageIDs = paginatedMessages.map(
        (message) => message.MessageID
      );
      setSelectedMessages(allMessageIDs);
    } else {
      setSelectedMessages([]);
    }
  };

  const handleDeleteSelected = () => {
    selectedMessages.forEach((messageID) => {
      handleDeleteMessage(messageID);
    });
    setSelectedMessages([]);
  };

  const filteredMessages = Array.isArray(messages)
    ? messages.filter(
        (message) =>
          message.Subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (message.LastName &&
            message.LastName.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    : [];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedMessages = filteredMessages.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Render the component UI
  return (
    <div className="overview-container">
      <main className="messages-content">
        <TextField
          variant="outlined"
          placeholder={t('search_messages')}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          value={searchTerm}
          onChange={handleSearch}
          className="search-bar"
          sx={{ mb: 3, mr: 2 }}
        />

        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteSelected}
          disabled={selectedMessages.length === 0}
          sx={{ mb: 2 }}
        >
          {t('delete_selected')}
        </Button>

        <div className="messages-table-container" style={{ overflowY: 'auto' }}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Table className="table table-bordered">
                <TableHead
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    backgroundColor: '#d5deee',
                  }}
                >
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selectedMessages.length > 0 &&
                          selectedMessages.length < paginatedMessages.length
                        }
                        checked={
                          paginatedMessages.length > 0 &&
                          selectedMessages.length === paginatedMessages.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>{t('subject')}</TableCell>
                    <TableCell>{t('tenant_name')}</TableCell>
                    <TableCell>{t('sent_date')}</TableCell>
                    <TableCell>{t('delete')}</TableCell>
                  </TableRow>
                </TableHead>

                {/* Table body showing filtered messages */}
                <TableBody>
                  {paginatedMessages.map((message) => (
                    <TableRow
                      key={message.MessageID}
                      onClick={() => handleRowClick(message)}
                      className="table-row"
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedMessages.includes(message.MessageID)}
                          onClick={(e) => e.stopPropagation()} // Prevent checkbox clicks from triggering row click
                          onChange={() =>
                            handleSelectMessage(message.MessageID)
                          }
                        />
                      </TableCell>
                      <TableCell>{message.Subject}</TableCell>
                      <TableCell>
                        {message.FirstName} {message.LastName}
                      </TableCell>
                      {/* @ts-ignore */}
                      <TableCell>{formatDate(message.SentDate)}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label={t('delete')}
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteMessage(message.MessageID);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {/* Pagination controls */}
              <div
                style={{
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: '#fff',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '4px', // Reduced padding to decrease height
                  zIndex: 1000,
                }}
              >
                <Typography variant="body2">
                  {t('page_of', {
                    page: page + 1,
                    totalPages: Math.ceil(
                      filteredMessages.length / rowsPerPage
                    ),
                  })}
                </Typography>
                <TablePagination
                  component="div"
                  count={filteredMessages.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 25, 50]}
                  sx={{ '.MuiTablePagination-toolbar': { minHeight: '36px' } }} // Reduced height of pagination toolbar
                />
              </div>
            </>
          )}
        </div>

        {selectedMessage && ( // Show modal if a message is selected
          <Modal
            open={showModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Paper className="modal-content">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {selectedMessage.Subject}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {selectedMessage.Description}
              </Typography>
              <Button
                variant="contained"
                onClick={handleCloseModal}
                sx={{ mt: 2 }}
              >
                {t('close')}
              </Button>
            </Paper>
          </Modal>
        )}
      </main>
    </div>
  );
}

export default Messages;
