import { Container, Typography, Box, Grid, Link } from '@mui/material';
import { Email, Phone, LocationOn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Plan } from '../utils/interfaces';
import { usePlans } from '../helper/plans';

const AboutUs = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const planData = usePlans();

  return (
    <div className="overview-container">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          padding: '20px',
          overflowY: 'auto',
        }}
      >
        <main className="about-us-content">
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              sx={{ color: '#333', fontWeight: 'bold', marginBottom: '20px' }}
            >
              {t('aboutUs.title')}
            </Typography>

            <Typography
              variant="body1"
              sx={{ fontSize: '18px', lineHeight: 1.8, marginBottom: '20px' }}
            >
              {t('aboutUs.introduction', { brand: 'LeaseSphere' })}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: '18px', lineHeight: 1.8, marginBottom: '40px' }}
            >
              {t('aboutUs.details', { brand: 'LeaseSphere' })}
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                color: '#333',
                fontWeight: 'bold',
                marginBottom: '20px',
              }}
            >
              {t('aboutUs.pricingTitle')}
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBottom: '20px', fontSize: '16px', lineHeight: 1.7 }}
            >
              {t('aboutUs.pricingDescription')}
            </Typography>

            <Grid container spacing={4}>
              {planData.map((plan: Plan, index: number) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box
                    sx={{
                      backgroundColor:
                        plan.styleClass === 'basic'
                          ? '#ff5f5f'
                          : plan.styleClass === 'standard'
                            ? '#427700'
                            : '#4d5fff', // blue for premium
                      color: '#fff',
                      padding: '20px',
                      borderRadius: '8px',
                      textAlign: 'center',
                      transition: 'transform 0.3s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    <Typography variant="h6" component="h3">
                      {plan.name}
                    </Typography>
                    <Typography variant="body2">
                      {plan.features.join(', ')}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h3"
                      sx={{ marginTop: '10px' }}
                    >
                      {plan.price} / {t('subscriptions.monthly')}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Box
              sx={{
                marginTop: '40px',
                paddingTop: '20px',
                borderTop: '1px solid #ddd',
                textAlign: 'center',
                color: '#fff',
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: '10px', fontWeight: 'bold' }}
              >
                {t('contactUs.title')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '20px',
                  marginBottom: '20px',
                  flexWrap: 'wrap',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Email sx={{ marginRight: '8px' }} />
                  <Link
                    href="mailto:support@leasesphere.com"
                    underline="none"
                    sx={{ color: '#fff' }}
                  >
                    support@leasesphere.com
                  </Link>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Phone sx={{ marginRight: '8px' }} />
                  <Link
                    href="tel:+2347030603370"
                    underline="none"
                    sx={{ color: '#fff' }}
                  >
                    +234 703 060 3370
                  </Link>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocationOn sx={{ marginRight: '8px' }} />
                  <Typography variant="body2" sx={{ color: '#fff' }}>
                    {t('contactUs.location')}
                  </Typography>
                </Box>
              </Box>

              <Typography
                variant="body2"
                sx={{ color: '#fff', fontSize: '12px' }}
              >
                {t('copyright')} © {currentYear} |{' '}
                {t('powered_by', { brand: 'LeaseSphere' })} <br />
                {t('rightreserved')}
              </Typography>
            </Box>
          </Container>
        </main>
      </Box>
    </div>
  );
};

export default AboutUs;
