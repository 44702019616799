import axios, { AxiosResponse } from 'axios';
import { getDecryptedAccessToken } from '../utils/TokenStorage';
import {
  User,
  AccessRequest,
  Subscription,
  Property,
  Room,
  Tenant,
  RentalIncomeResponse,
  ApiResponse,
  RoomResponse,
  Message,
  CustomError,
  ExchangeRateResponse,
} from './interfaces';

const apiUrl =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

console.log('Environment:', process.env.REACT_APP_ENV || process.env.NODE_ENV);

const httpClient = axios.create({
  baseURL: apiUrl,
  withCredentials: true, // Ensure credentials are sent
});

// #region Headers

const getAuthHeaders = () => {
  const token = getDecryptedAccessToken();
  const language = localStorage.getItem('i18nextLng') || 'en'; // Default to 'en' if not set
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': language,
    },
    withCredentials: true,
  };
};

const getUploadHeaders = () => {
  const token = getDecryptedAccessToken();
  const language = localStorage.getItem('i18nextLng') || 'en'; // Default to 'en' if not set
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': language,
    },
    withCredentials: true,
  };
};

// #endregion

// #region API Authenticate

const authUser = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`${apiUrl}/auth`, {}, getAuthHeaders())
      .then((response: AxiosResponse<{ success: boolean }>) => {
        resolve(response.data.success);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

// #endregion

// #region API Register

export const register = async (user: User): Promise<any> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/register`,
      user,
      getAuthHeaders()
    );

    return {
      data: response.data,
      headers: response.headers,
    };
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

// #region API User

export const login = async (user: User): Promise<any> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/login`,
      user,
      getAuthHeaders()
    );

    return {
      data: response.data,
      headers: response.headers,
    };
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const getUserDetails = async (): Promise<User> => {
  try {
    const response = await httpClient.get(`${apiUrl}/user`, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const deleteAccount = async (email: string): Promise<any> => {
  try {
    const response = await httpClient.delete(
      `${apiUrl}/delete-account/${email}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const updateUserProfile = async (user: User): Promise<User> => {
  try {
    const response = await httpClient.put(
      `${apiUrl}/user`,
      user,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const uploadAvatar = async (user: User): Promise<any> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/user/upload_avatar`,
      user,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const updateUserLanguage = async (
  defaultLanguage: string
): Promise<void> => {
  try {
    const response = await httpClient.put(
      `${apiUrl}/user/language`,
      { defaultLanguage },
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const subscribe = async (Email: string): Promise<any> => {
  try {
    const response = await httpClient.post<any>(
      `${apiUrl}/subscribe`,
      { Email },
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

// #region API Tenants

export const createTenant = async (tenant: Tenant): Promise<any> => {
  try {
    let response;
    if (tenant.file) {
      response = await httpClient.post(
        `${apiUrl}/tenants`,
        tenant,
        getUploadHeaders()
      );
    } else {
      response = await httpClient.post(
        `${apiUrl}/tenants`,
        tenant,
        getAuthHeaders()
      );
    }

    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const getTenants = async (): Promise<Tenant[]> => {
  try {
    const response = await httpClient.get(
      `${apiUrl}/tenants`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const updateTenant = async (
  propertyId: string,
  tenantId: number,
  updatedTenantData: Tenant
): Promise<any> => {
  try {
    const response = await httpClient.put(
      `${apiUrl}/tenants/${propertyId}/${tenantId}`,
      updatedTenantData,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const deleteTenant = async (
  propertyId: string,
  tenantId: number
): Promise<any> => {
  try {
    const response = await httpClient.delete(
      `${apiUrl}/tenants/${propertyId}/${tenantId}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const downloadFile = async (tenantID: number): Promise<any> => {
  try {
    const response = await httpClient.get(
      `${apiUrl}/tenants/${tenantID}/download-pdf`,
      {
        responseType: 'blob', // Set the response type to 'blob' to handle binary data
        headers: getAuthHeaders().headers,
      }
    );

    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

// #region API Rooms

export const createRoom = async (room: Room): Promise<Room> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/properties/rooms`,
      room,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const getRoomsByID = async (
  propertyID: string
): Promise<RoomResponse[]> => {
  try {
    const response = await httpClient.get(
      `${apiUrl}/properties/${propertyID}/rooms`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

// #region API Property

export const createProperty = async (property: Property): Promise<Property> => {
  try {
    const response = await httpClient.post<Property>(
      '/properties',
      property,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const getPropertiesByUser = async (): Promise<any> => {
  try {
    const response = await httpClient.get(
      `${apiUrl}/properties`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const getProperties = async (): Promise<any> => {
  try {
    const response = await httpClient.get(
      `${apiUrl}/all-properties`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const updateProperty = async (
  propertyId: string,
  property: Property
): Promise<any> => {
  try {
    const response = await httpClient.put(
      `${apiUrl}/properties/${propertyId}`,
      property,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const deleteProperty = async (propertyId: string): Promise<any> => {
  try {
    const response = await httpClient.delete(
      `${apiUrl}/properties/${propertyId}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

// #region API Subscription

export const createSubscription = async (
  subscription: Subscription
): Promise<any> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/subscriptions`,
      subscription,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const getSubscription = async (): Promise<any> => {
  try {
    const response = await httpClient.get(
      `${apiUrl}/subscriptions`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const updateSubscription = async (
  subscription: Subscription
): Promise<any> => {
  try {
    const SubscriptionID = subscription.SubscriptionID;
    const response = await httpClient.put(
      `${apiUrl}/subscriptions/${SubscriptionID}`,
      subscription,
      getUploadHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const deleteSubscription = async (
  subscriptionId: number
): Promise<any> => {
  try {
    const response = await httpClient.delete(
      `${apiUrl}/subscriptions/${subscriptionId}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

// #region API Access Request

export const createAccessRequest = async (
  accessRequest: AccessRequest
): Promise<any> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/access-requests`,
      accessRequest,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const updateAccessRequest = async (
  accessRequest: AccessRequest
): Promise<any> => {
  try {
    const requestId = accessRequest.RequestID;
    const response = await httpClient.put(
      `${apiUrl}/access-requests/${requestId}`,
      accessRequest,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const deleteAccessRequest = async (requestId: number): Promise<any> => {
  try {
    const response = await httpClient.delete(
      `${apiUrl}/access-requests/${requestId}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const getAccessRequests = async (): Promise<any> => {
  try {
    const response = await httpClient.get(
      `${apiUrl}/access-requests`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

// #region Verification Links

export const fetchData = async (
  endpoint: string,
  token: string
): Promise<ApiResponse> => {
  const url = `${apiUrl}/${endpoint}/${token}`;

  try {
    const response = await axios.get(url, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const verifyEmailLink = async (token: string): Promise<ApiResponse> => {
  try {
    return await fetchData('verify_email', token);
  } catch (error) {
    // Handle or log the error as needed
    throw error; // Re-throw to propagate the error up the call stack
  }
};

export const verifyResetLink = async (token: string): Promise<ApiResponse> => {
  try {
    return await fetchData('verify_password_reset', token);
  } catch (error) {
    // Handle or log the error as needed
    throw error; // Re-throw to propagate the error up the call stack
  }
};

export const verifySubscriptionLink = async (
  token: string
): Promise<ApiResponse> => {
  try {
    return await fetchData('verify_subscription', token);
  } catch (error) {
    // Handle or log the error as needed
    throw error; // Re-throw to propagate the error up the call stack
  }
};

// #endregion

// #region API Request Password Reset

export const resetPasswordEmail = async (email: string): Promise<any> => {
  try {
    const requestData = {
      email: email,
    };

    const response = await httpClient.put(
      `${apiUrl}/account_recovery`,
      requestData,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const changePassword = async (params: any): Promise<any> => {
  try {
    const response = await httpClient.put(
      `${apiUrl}/user/reset_password`,
      params,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

// #region Messages

export const sendMessage = async (message: Message): Promise<Message> => {
  try {
    const response = await httpClient.post<Message>(
      `${apiUrl}/messages`,
      message,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const getMessages = async (): Promise<Message[]> => {
  try {
    const response = await axios.get(`${apiUrl}/messages`, getAuthHeaders());
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const deleteMessage = async (messageId: number): Promise<void> => {
  try {
    await httpClient.delete(
      `${apiUrl}/messages/${messageId}`,
      getAuthHeaders()
    );
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const contactUsEmail = async (message: {
  Subject: string;
  Description: string;
}): Promise<string> => {
  try {
    const response = await httpClient.post<string>(
      `${apiUrl}/contact-us`,
      message,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

//#region API Version

// POST
export const UpdateVersion = async (
  version: string,
  description: string
): Promise<any> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/version`,
      { version, description },
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const getVersionUpdate = async (): Promise<any> => {
  try {
    const response = await httpClient.get(
      `${apiUrl}/version/latest`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

//#endregion

// #region API Income Data

export const getRentalIncome = async (): Promise<RentalIncomeResponse> => {
  try {
    const response = await httpClient.get(
      `${apiUrl}/rental-income`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

// #region API ExchangeRate

export const getCurrencyConversionRate = async (
  currencyCode: string
): Promise<number> => {
  try {
    const response = await fetch(
      `https://v6.exchangerate-api.com/v6/25769f63564c46a926d87429/latest/USD`
    );

    if (!response.ok) {
      throw new Error('Failed to fetch currency conversion rates');
    }

    const data = await response.json();

    if (
      data &&
      data.conversion_rates &&
      typeof data.conversion_rates[currencyCode] === 'number'
    ) {
      return data.conversion_rates[currencyCode];
    } else {
      throw new Error('Invalid conversion rate data');
    }
  } catch (error) {
    console.error('Error fetching conversion rate:', error);
    throw extractErrorDetails(error);
  }
};

export const getExchangeRate = async (): Promise<ExchangeRateResponse> => {
  try {
    const response = await httpClient.get(
      `${apiUrl}/exchange-rate`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

export const saveExchangeRate = async (
  rateData: ExchangeRateResponse
): Promise<ExchangeRateResponse> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/exchange-rate`,
      rateData,
      getAuthHeaders()
    );

    return response.data.message;
  } catch (error) {
    throw extractErrorDetails(error);
  }
};

// #endregion

// #region Helper Functions

const extractErrorDetails = (error: any): CustomError => {
  let customError: CustomError;

  if (axios.isAxiosError(error)) {
    if (error.response) {
      customError = {
        name: 'CustomError',
        statusCode: error.response.status,
        statusText: error.response.statusText,
        message:
          error.response.data?.error ||
          error.response.data?.message ||
          'An error occurred',
        context: error.response.data?.context,
      };
    } else {
      customError = {
        name: 'CustomError',
        statusCode: 500,
        statusText: 'Internal Server Error',
        message: error.message || 'An unknown error occurred',
      };
    }
  } else {
    // Handle non-Axios errors (e.g., network errors)
    customError = {
      name: 'CustomError',
      statusCode: 500,
      statusText: 'Internal Server Error',
      message: error.message || 'An unknown error occurred',
    };
  }

  return customError;
};

// Type guard to check if error is CustomError
export function isCustomError(error: any): error is CustomError {
  return (error as CustomError).message !== undefined;
}

// #endregion

export const api = {
  ...httpClient,
  delete: httpClient.delete,
  get: httpClient.get,
  patch: httpClient.patch,
  post: httpClient.post,
  put: httpClient.put,
  login,
  register,
  contactUsEmail,
  getUserDetails,
  uploadAvatar,
  updateUserProfile,
  updateUserLanguage,
  authUser,
  subscribe,
  resetPasswordEmail,
  verifyEmailLink,
  verifyResetLink,
  verifySubscriptionLink,
  changePassword,
  getTenants,
  createTenant,
  deleteTenant,
  updateTenant,
  getRentalIncome,
  createRoom,
  getRoomsByID,
  sendMessage,
  getMessages,
  deleteMessage,
  createProperty,
  getPropertiesByUser,
  getProperties,
  updateProperty,
  deleteProperty,
  createSubscription,
  getSubscription,
  updateSubscription,
  deleteSubscription,
  createAccessRequest,
  updateAccessRequest,
  deleteAccessRequest,
  getAccessRequests,
  downloadFile,
  deleteAccount,
  getCurrencyConversionRate,
  UpdateVersion,
  getVersionUpdate,
  getExchangeRate,
  saveExchangeRate,
  isCustomError,
};
