import { openDB, IDBPDatabase } from 'idb';
import CryptoJS from 'crypto-js';

const DB_NAME = 'userDataDB';
const STORE_NAME = 'userData';

interface UserData {
  id: string;
  data: string;
}

export const initDB = async (): Promise<IDBPDatabase> => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
      }
    },
  });
};

export const saveData = async (id: string, data: string): Promise<void> => {
  const db = await initDB();
  await db.put(STORE_NAME, { id, data });
};

export const getData = async (id: string): Promise<UserData | undefined> => {
  const db = await initDB();
  return db.get(STORE_NAME, id);
};

export const clearAllData = async (): Promise<void> => {
  const db = await initDB();
  await db.clear(STORE_NAME);
};

// Function to delete a specific entry from an object store
export const deleteKeyStore = async (key: string): Promise<void> => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  await store.delete(key); // Deletes the specific entry by key
  await tx.done; // Waits for the transaction to complete
};
const SECRET_KEY =
  '62835caa5432d037167a7822f3bb8b64992a36ad1a459e10b1a20f7e416ae097';

export const encryptData = (data: any): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

export const decryptData = (encryptedData: string): any => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
