import { create } from 'zustand';

interface OverviewStore {
  hasVersionNotifications: boolean;
  hasRequestNotifications: boolean;
  releaseDate: Date | null;
  updateModalOpen: boolean;
  setHasVersionNotifications: (value: boolean) => void;
  setHasRequestNotifications: (value: boolean) => void;
  setReleaseDate: (date: Date | null) => void;
  setUpdateModalOpen: (open: boolean) => void;
}

export const useOverviewStore = create<OverviewStore>((set) => ({
  hasVersionNotifications: false,
  hasRequestNotifications: false,
  releaseDate: null,
  updateModalOpen: false,
  setHasVersionNotifications: (value: boolean) =>
    set({ hasVersionNotifications: value }),
  setHasRequestNotifications: (value: boolean) =>
    set({ hasRequestNotifications: value }),
  setReleaseDate: (date: Date | null) => set({ releaseDate: date }),
  setUpdateModalOpen: (open: boolean) => set({ updateModalOpen: open }),
}));
