import { useTranslation } from 'react-i18next';

export const usePlans = () => {
  const { t } = useTranslation();

  return [
    {
      name: t('subscriptions.basic.name'),
      price: '$4.99',
      monthly: t('subscriptions.monthly'),
      features: [
        t('subscriptions.features.3properties'),
        t('subscriptions.features.50tenants'),
        t('subscriptions.features.noanalysisoverview'),
      ],
      styleClass: 'basic',
    },
    {
      name: t('subscriptions.standard.name'),
      price: '$8.99',
      monthly: t('subscriptions.monthly'),
      features: [
        t('subscriptions.features.unlimitedproperties'),
        t('subscriptions.features.200tenants'),
        t('subscriptions.features.noanalysisoverview'),
      ],
      styleClass: 'standard',
    },
    {
      name: t('subscriptions.premium.name'),
      price: '$12.99',
      monthly: t('subscriptions.monthly'),
      features: [
        t('subscriptions.features.unlimitedproperties'),
        t('subscriptions.features.nolimits'),
        t('subscriptions.features.analysisoverview'),
      ],
      styleClass: 'premium',
    },
  ];
};
