import { useQuery } from '@tanstack/react-query';
import { api } from '../utils/api';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { Tenant } from '../utils/interfaces';
import { calculateStatus } from '../utils/dateUtils';
import { useTenantStore } from '../stores/TenantStore';
import { t } from 'i18next';

// Fetch tenants from API
const fetchTenantsFromApi = async (): Promise<Tenant[]> => {
  const response = await api.getTenants();
  const updatedTenants = response.map((tenant: Tenant) => {
    const status = calculateStatus(tenant.EndDate);
    tenant.Status = t(status.toLowerCase());
    return tenant;
  });

  // Sort alphabetically by 'FirstName' and 'LastName'
  updatedTenants.sort((a: Tenant, b: Tenant) => {
    const nameA = `${a.FirstName} ${a.LastName}`.toUpperCase();
    const nameB = `${b.FirstName} ${b.LastName}`.toUpperCase();
    return nameA.localeCompare(nameB);
  });

  // Encrypt and save the tenants data to IndexedDB
  const encryptedData = encryptData(JSON.stringify(updatedTenants));
  await saveData('tenants', encryptedData);

  return updatedTenants;
};

// Load tenants from IndexedDB
const loadTenantsFromIndexedDB = async (): Promise<Tenant[] | null> => {
  const savedTenants = await getData('tenants');

  if (savedTenants && savedTenants.data.length > 0) {
    const decryptedData = decryptData(savedTenants.data);
    return JSON.parse(decryptedData);
  } else {
    return null;
  }
};

// React Query hook to fetch tenants
export const useFetchTenants = () => {
  const { setTenants } = useTenantStore();

  return useQuery<Tenant[]>({
    queryKey: ['tenants'],
    queryFn: async () => {
      const tenantsFromDB = await loadTenantsFromIndexedDB();
      if (tenantsFromDB) {
        setTenants(tenantsFromDB);
        return tenantsFromDB;
      } else {
        const tenantsFromApi = await fetchTenantsFromApi();
        setTenants(tenantsFromApi);
        return tenantsFromApi;
      }
    },
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
  });
};
