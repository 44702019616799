import { create } from 'zustand';
import { Property } from '../utils/interfaces';

interface PropertyStore {
  properties: Property[] | null;
  setProperties: (properties: Property[]) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const usePropertyStore = create<PropertyStore>((set) => ({
  properties: null,
  loading: false,

  // Function to set properties in the store
  setProperties: (properties: Property[]) => set({ properties }),

  // Function to set loading state
  setLoading: (loading: boolean) => set({ loading }),
}));
