import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../utils/api';
import { ApiResponse, Token } from '../utils/interfaces';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function VerifyEmail() {
  const { token } = useParams<Token>();
  const [verificationStatus, setVerificationStatus] =
    useState<string>('failed');
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      api
        .verifyEmailLink(token)
        .then((response: ApiResponse) => {
          if (response.success) {
            setVerificationStatus('success');
            setIsSuccess(true);
          } else {
            setVerificationStatus('failed');
            setIsSuccess(false);
          }
        })
        .catch((error) => {
          setVerificationStatus('failed');
          setIsSuccess(false);
          toast.error(error.message);
        });
    } else {
      setVerificationStatus('invalid_token');
      setIsSuccess(false);
    }
  }, [token]);

  return (
    <div
      className="verification-container"
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h2>{t('verifyEmail.title')}</h2>
      {isSuccess && (
        <div className="success-message">
          <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
          <p>{t(`verifyEmail.${verificationStatus}`)}</p>
        </div>
      )}
      {!isSuccess && (
        <div className="failure-message">
          <FontAwesomeIcon icon={faTimesCircle} className="failure-icon" />
          <p>{t(`verifyEmail.${verificationStatus}`)}</p>
        </div>
      )}
    </div>
  );
}

export default VerifyEmail;
