import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { api } from '../utils/api';
import { Authenticator } from '../helper/Authenticator';
import { toast } from 'react-toastify';
import { setEncryptedAccessToken } from '../utils/TokenStorage';
import ProppLogo from '../IOT/Logo';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { validateEmail, validatePassword } from '../helper/validation';
import { User, ApiResponse } from '../utils/interfaces';

function Login() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMsgLogin, setErrorMsgLogin] = useState('');
  const [errorMsgRegister, setErrorMsgRegister] = useState('');
  const [errorMsgReset, setErrorMsgReset] = useState('');
  const [honeypot, setHoneypot] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toggleForm = () => {
    setShowLoginForm(!showLoginForm);
    setShowForgotPassword(false);
  };

  const toggleForgetForm = () => {
    setShowLoginForm(false);
    setShowForgotPassword(true);
  };

  const handleRegister = (data: any) => {
    const { email, password, passwordConfirm, userRoleType } = data;

    if (honeypot) {
      console.log('Bot detected - honeypot filled');
      return;
    }

    if (!validateEmail(email)) {
      setErrorMsgRegister(t('email_not_valid'));
      return;
    } else {
      const passwordErrorKey = validatePassword(password);
      if (passwordErrorKey) {
        setErrorMsgRegister(t(passwordErrorKey));
        return;
      } else if (password !== passwordConfirm) {
        setErrorMsgRegister(t('passwords_do_not_match'));
        return;
      }
    }

    if (!userRoleType) {
      setErrorMsgRegister(t('please_select_role'));
      return;
    }

    const languageCode = i18n.language.split('-')[0];
    const userName = email.split('@')[0];
    const registerData: User = {
      Username: userName,
      Password: password,
      Email: email,
      UserRoleType: userRoleType,
      LanguageCode: languageCode,
    };

    api
      .register(registerData)
      .then((response) => {
        const accessToken = response.headers['authorization']?.split(' ')[1];
        if (accessToken) {
          setEncryptedAccessToken(accessToken);
          // @ts-ignore
          Authenticator.setAuth(true);
          navigate('/overview', { replace: true });
        }
      })
      .catch((error: ApiResponse) => {
        toast.error(error.message);
        console.log(error.message);
      });
  };

  const handleLogin = (data: any) => {
    const { email, password, rememberMe } = data;

    if (honeypot) {
      console.log('Bot detected - honeypot filled');
      return;
    }

    if (!validateEmail(email)) {
      setErrorMsgLogin(t('email_not_valid'));
      return;
    }

    const loginData: User = {
      Email: email,
      Password: password,
      RememberMe: rememberMe,
    };

    api
      .login(loginData)
      .then((response) => {
        const accessToken = response.headers['authorization']?.split(' ')[1];
        if (accessToken) {
          setEncryptedAccessToken(accessToken);
          // @ts-ignore
          Authenticator.setAuth(true);
          navigate('/overview', { replace: true });
        }
      })
      .catch((error: ApiResponse) => {
        toast.error(error.message);
        console.log(error.message);
      });
  };

  const handleResetPassword = (data: any) => {
    const { email } = data;

    if (honeypot) {
      console.log('Bot detected - honeypot filled');
      return;
    }

    if (!validateEmail(email)) {
      setErrorMsgReset(t('invalid_email'));
      return;
    }

    api
      .resetPasswordEmail(email)
      .then((response) => {
        toast.success(t(response.message));
        setShowLoginForm(true);
        setShowForgotPassword(false);
        setErrorMsgReset('');
      })
      .catch((error: ApiResponse) => {
        setErrorMsgReset(error.message);
        toast.error(error.message);
        console.log(error.message);
      });
  };

  const goToAboutUs = () => {
    navigate('/about-us');
  };

  return (
    <div className="login-container">
      <form
        className="login-form"
        onSubmit={handleSubmit(showLoginForm ? handleLogin : handleRegister)}
      >
        {/* Honeypot field */}
        <input
          type="text"
          name="honeypot"
          style={{ display: 'none' }}
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
        />
        {showLoginForm ? (
          <>
            <div className="logo">
              {/* @ts-ignore */}
              <ProppLogo id="propLogo" />
            </div>
            <TextField
              label={t('email')}
              type="email"
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
              {...register('email', { required: true })}
              error={!!errors.email}
              helperText={errors.email && t('email_required')}
            />
            <TextField
              label={t('password')}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('password', { required: true })}
              error={!!errors.password}
              helperText={errors.password && t('password_required')}
            />
            <FormControlLabel
              control={<Checkbox {...register('rememberMe')} />}
              label={t('remember_me')}
            />
            <Typography color="error">{errorMsgLogin}</Typography>
            <Button variant="contained" color="primary" fullWidth type="submit">
              {t('login')}
            </Button>
            <p className="message">
              <Button
                onClick={toggleForgetForm}
                sx={{ textDecoration: 'underline', color: 'inherit' }}
              >
                {t('forgot_password')}
              </Button>
            </p>
            <p className="message">
              <Button
                onClick={toggleForm}
                sx={{ textDecoration: 'underline', color: 'inherit' }}
              >
                {t('sign_up')}
              </Button>
            </p>
            <Button onClick={goToAboutUs}>{t('about_us')}</Button>
          </>
        ) : showForgotPassword ? (
          <>
            <div className="logo">
              {/* @ts-ignore */}
              <ProppLogo id="propLogo" />
            </div>
            <TextField
              label={t('email')}
              type="email"
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
              {...register('email', { required: true })}
              error={!!errors.email}
              helperText={errors.email && t('email_required')}
            />
            <Typography color="error">{errorMsgReset}</Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit(handleResetPassword)}
            >
              {t('reset_password')}
            </Button>
            <p className="message">
              <Button
                onClick={toggleForm}
                sx={{ textDecoration: 'underline', color: 'inherit' }}
              >
                {t('sign_in')}
              </Button>
            </p>
          </>
        ) : (
          <>
            <div className="logo">
              {/* @ts-ignore */}
              <ProppLogo id="propLogo" />
            </div>
            <TextField
              label={t('email')}
              type="email"
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
              {...register('email', { required: true })}
              error={!!errors.email}
              helperText={errors.email && t('email_required')}
            />
            <TextField
              label={t('password')}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('password', { required: true })}
              error={!!errors.password}
              helperText={errors.password && t('password_required')}
            />
            <TextField
              label={t('confirm_password')}
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ width: '300px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('passwordConfirm', { required: true })}
              error={!!errors.passwordConfirm}
              helperText={
                errors.passwordConfirm && t('confirm_password_required')
              }
            />
            <FormControl fullWidth>
              <InputLabel>{t('role')}</InputLabel>
              <Select
                label={t('role')}
                sx={{ width: '300px' }}
                {...register('userRoleType', { required: true })}
                error={!!errors.userRoleType}
              >
                <MenuItem value="Landlord">{t('landlord')}</MenuItem>
                <MenuItem value="Caretaker">{t('caretaker')}</MenuItem>
              </Select>
              {errors.userRoleType && (
                <Typography color="error">{t('please_select_role')}</Typography>
              )}
            </FormControl>
            <Typography color="error">{errorMsgRegister}</Typography>
            <Button variant="contained" color="primary" fullWidth type="submit">
              {t('register')}
            </Button>
            <p className="message">
              <Button
                onClick={toggleForm}
                sx={{ textDecoration: 'underline', color: 'inherit' }}
              >
                {t('sign_in')}
              </Button>
            </p>
          </>
        )}
      </form>
    </div>
  );
}

export default Login;
