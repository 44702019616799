import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../utils/Spinner';
import { useTranslation } from 'react-i18next';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import {
  Avatar,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Drawer,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { toast } from 'react-toastify';
import { Authenticator } from '../helper/Authenticator';
//@ts-ignore
import settingsIcon from '../assets/img/settings.png';
//@ts-ignore
import logoutIcon from '../assets/img/logout.png';
import avatars from '../utils/avatars';
import { clearAllData } from '../utils/indexedDB';
import { useFetchUser } from '../hooks/user.hooks';

const Sidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const errorMessages = new ErrorMessages();

  const {
    data: user,
    isError: isUserError,
    error: userError,
    isLoading: isUserLoading,
  } = useFetchUser();

  useEffect(() => {
    if (isUserLoading) {
      <Spinner />;
      return;
    }
  }, [isUserLoading]);

  useEffect(() => {
    if (isUserError) {
      ErrorHandler.handleError(userError, errorMessages, toast);
    }
  }, [isUserError, userError]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (user) {
          //@ts-ignore
          Authenticator.setUserRoleType(user?.UserRoleType);
          //@ts-ignore
          Authenticator.setSubscriptionType(user?.SubscriptionType);
          localStorage.setItem('Currency', user?.Currency || 'Unknown');
        }
      } catch (error) {
        ErrorHandler.handleError(error, errorMessages, toast);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.UserRoleType, user?.Avatar]);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const navigateTo = (path: string) => {
    navigate(path, { replace: true });
    if (isMobile) {
      setDrawerOpen(false); // Close the drawer when navigating on mobile
    }
  };

  // Function to logout user and clear localStorage
  const logOutNavigate = () => {
    localStorage.clear();
    clearAllData()
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Error clearing IndexedDB:', error.message);
        window.location.href = '/';
      });
  };

  // Memoized avatarUrl to select the correct avatar based on profile.Avatar
  const avatarUrl = useMemo(() => {
    if (user && user.Avatar) {
      const filename = user.Avatar;
      const selectedAvatar = avatars.find((avatar) =>
        avatar.includes(filename)
      );
      return selectedAvatar || avatars[14];
    } else {
      return avatars[14];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.Avatar]);

  if (isLoading) {
    return null; // Or return a loading spinner, if desired
  }

  const sidebarContent = (
    <>
      <div className="logo"></div>
      <div className="user-info">
        {/* Displaying user avatar */}
        <Avatar
          className="user-avatar"
          src={avatarUrl}
          style={{ width: '100px', height: '100px' }}
        />

        {/* Displaying user details */}
        <div className="user-details">
          <Typography
            className="username"
            variant="subtitle1"
            style={{ textTransform: 'capitalize' }}
          >
            {user?.Username}
          </Typography>
          <Typography className="user-role" variant="body2">
            {t(user?.UserRoleType ? user.UserRoleType : '')}
          </Typography>
        </div>
      </div>
      {/* Sidebar menu items */}
      <List className="sidebar-menu">
        <ListItemButton onClick={() => navigateTo('/overview')}>
          <ListItemText primary={t('overview')} />
          <div style={{ marginLeft: '10px', color: 'white' }}>
            <span className="material-symbols-outlined">dashboard</span>
          </div>
        </ListItemButton>

        <ListItemButton onClick={() => navigateTo('/property')}>
          <ListItemText primary={t('properties')} />
          <div style={{ marginLeft: '10px', color: 'white' }}>
            <span className="material-symbols-outlined">real_estate_agent</span>
          </div>
        </ListItemButton>

        <ListItemButton onClick={() => navigateTo('/tenant')}>
          <ListItemText primary={t('tenants')} />
          <div style={{ marginLeft: '10px', color: 'white' }}>
            <span className="material-symbols-outlined">reduce_capacity</span>
          </div>
        </ListItemButton>

        {/* Show Messages only if user role is NOT 'Caretaker' */}
        {!isLoading && user?.UserRoleType === 'Landlord' && (
          <ListItemButton onClick={() => navigateTo('/messages')}>
            <ListItemText primary={t('messages')} />
            <div style={{ marginLeft: '10px', color: 'white' }}>
              <span className="material-symbols-outlined">mail</span>
            </div>
          </ListItemButton>
        )}

        {/* Show Subscription only if user role is NOT 'Caretaker' */}
        {!isLoading && user?.UserRoleType === 'Landlord' && (
          <ListItemButton onClick={() => navigateTo('/plantype')}>
            <ListItemText primary={t('subscription')} />
            <div style={{ marginLeft: '10px', color: 'white' }}>
              <span className="material-symbols-outlined">card_membership</span>{' '}
            </div>
          </ListItemButton>
        )}

        {/* Show Requests only if user role is NOT 'Landlord' */}
        {!isLoading && user?.UserRoleType === 'Caretaker' && (
          <ListItemButton onClick={() => navigateTo('/requests')}>
            <ListItemText primary={t('request')} />
            <div style={{ marginLeft: '10px', color: 'white' }}>
              <span className="material-symbols-outlined">help</span>
            </div>
          </ListItemButton>
        )}
      </List>
      {/* Sidebar footer with settings and logout buttons */}
      <div className="sidebar-footer">
        <div className="settings-logout">
          <Tooltip title={t('settings')} placement="top">
            <div
              className="settings-button menu-item"
              onClick={() => navigateTo('/profile')}
            >
              {/* Settings button to navigate to profile page */}
              <img
                src={settingsIcon}
                alt="Settings"
                style={{ width: '20px', height: '20px' }}
              />
            </div>
          </Tooltip>
          <Tooltip title={t('logout')} placement="top">
            <div className="settings-button menu-item" onClick={logOutNavigate}>
              {/* Logout button to log out user */}
              <img
                src={logoutIcon}
                alt="Logout"
                style={{ width: '20px', height: '20px' }}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );

  return (
    <>
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <div className="sidebar drawer-content">{sidebarContent}</div>
          </Drawer>
        </>
      ) : (
        <aside className="sidebar">{sidebarContent}</aside>
      )}
    </>
  );
};

export default Sidebar;
