import { useQuery } from '@tanstack/react-query';
import { api } from '../utils/api';
import { toast } from 'react-toastify';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { Message } from '../utils/interfaces';
import { useMessageStore } from '../stores/MessageStore';

const errorMessages = new ErrorMessages();

// Fetch messages from IndexedDB
const loadMessagesFromIndexedDB = async (): Promise<Message[] | null> => {
  try {
    const savedMessages = await getData('messages');
    if (savedMessages && savedMessages.data.length > 0) {
      const decryptedData = decryptData(savedMessages.data);
      return JSON.parse(decryptedData) as Message[];
    }
    return null;
  } catch (error) {
    toast.error('Failed to load messages from IndexedDB');
    return null;
  }
};

// Fetch messages from API and save to IndexedDB
const fetchMessagesFromApi = async (): Promise<Message[]> => {
  try {
    const response = await api.getMessages();

    if (response && Array.isArray(response)) {
      // Encrypt and save the messages data to IndexedDB
      const encryptedData = encryptData(JSON.stringify(response));
      await saveData('messages', encryptedData);

      return response as Message[];
    }
    throw new Error('Invalid API response');
  } catch (error) {
    ErrorHandler.handleError(error, errorMessages, toast);
    throw error; // Important to propagate error for React Query
  }
};

// React Query hook to fetch messages, loading from IndexedDB first
export const useFetchMessages = () => {
  const { setMessages } = useMessageStore();

  return useQuery<Message[]>({
    queryKey: ['messages'],
    queryFn: async () => {
      // Try to load messages from IndexedDB first
      const messagesFromDB = await loadMessagesFromIndexedDB();

      if (messagesFromDB) {
        setMessages(messagesFromDB);
        return messagesFromDB; // Return messages from IndexedDB
      } else {
        // Fetch from the API
        const messagesFromApi = await fetchMessagesFromApi();
        setMessages(messagesFromApi); // Store messages in Zustand
        return messagesFromApi;
      }
    },
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
  });
};
