import { api } from '../utils/api';
import { useQuery } from '@tanstack/react-query';
import { RentalIncomeResponse } from '@/utils/interfaces';

export const useFetchRentalIncome = () => {
  return useQuery<RentalIncomeResponse>({
    queryKey: ['rentalincome'],
    queryFn: api.getRentalIncome,
    refetchOnWindowFocus: true,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
  });
};
