import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  TablePagination,
} from '@mui/material';
import { api } from '../utils/api';
import { Property, AccessRequest } from '../utils/interfaces';
import { useTranslation } from 'react-i18next';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';

function Request() {
  const { t } = useTranslation();

  // State variables
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(
    null
  );
  const [properties, setProperties] = useState<Property[]>([]);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const errorMessages = new ErrorMessages();

  // Fetch properties from API on component mount
  useEffect(() => {
    const fetchProperties = () => {
      api
        .getProperties()
        .then((response) => {
          setProperties(response);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    };

    fetchProperties();
  }, []);

  // Close selected property card
  const handleClose = () => {
    setSelectedProperty(null);
  };

  // Submit access request for selected property
  const handleSubmit = () => {
    if (selectedProperty) {
      // Check if a property is selected
      if (selectedProperty.UserID && selectedProperty.PropertyID) {
        // Prepare request data object
        const requestData: AccessRequest = {
          LandlordID: selectedProperty.UserID,
          PropertyID: selectedProperty.PropertyID,
          Status: 'Pending',
        };

        api
          .createAccessRequest(requestData)
          .then((response) => {
            toast.success(t(response.message));
            setSelectedProperty(null);
          })
          .catch((error) => {
            ErrorHandler.handleError(error, errorMessages, toast);
          });
      }
    }
  };

  // Handle filter change
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    setPage(0); // Reset to the first page on filter change
  };

  // Clear filter
  const handleClearFilter = () => {
    setFilter('');
    setPage(0); // Reset to the first page on filter clear
    setSelectedProperty(null);
  };

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page on rows per page change
  };

  // Filtered properties based on filter input
  const filteredProperties = properties.filter((property) =>
    property.PropertyName.toLowerCase().includes(filter.toLowerCase())
  );

  // Paginated properties
  const paginatedProperties = filteredProperties.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className="overview-container">
      <main className="request-content">
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              {/* Render selected property card if a property is selected */}
              {selectedProperty && (
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {t('property_name')}: {selectedProperty.PropertyName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t('address')}: {selectedProperty.Location}
                    </Typography>

                    {/* Close and Submit buttons */}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                      >
                        {t('close')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        {t('submit')}
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              {/* Header for property management request */}
              <Typography variant="h6">
                {t('request_property_manage_access')}
              </Typography>

              {/* Filter input and Clear Filter button */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <TextField
                  label={t('filterByPropertyName')}
                  variant="outlined"
                  value={filter}
                  onChange={handleFilterChange}
                  style={{ marginRight: '10px', width: '30%' }}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClearFilter}
                >
                  {t('clearFields')}
                </Button>
              </div>

              <div
                className="request-table-container"
                style={{ overflowY: 'auto' }}
              >
                {/* Header for properties list */}
                <Typography variant="h6" style={{ marginTop: '20px' }}>
                  {t('properties')}
                </Typography>

                <List style={{ maxHeight: '100vh', overflow: 'auto' }}>
                  {/* Map through paginated properties to render list items */}
                  {paginatedProperties.map((property) => (
                    <ListItemButton
                      key={property.PropertyID}
                      onClick={() => setSelectedProperty(property)}
                      className="list-item-hover"
                    >
                      <ListItemText
                        primary={property.PropertyName}
                        secondary={property.Location}
                      />
                    </ListItemButton>
                  ))}
                </List>

                {/* Pagination controls */}
                <div
                  style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: '#fff',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '4px', // Reduced padding to decrease height
                    zIndex: 1000,
                  }}
                >
                  <Typography variant="body2">
                    {t('page_of', {
                      page: page + 1,
                      totalPages: Math.ceil(
                        filteredProperties.length / rowsPerPage
                      ),
                    })}
                  </Typography>
                  <TablePagination
                    component="div"
                    count={filteredProperties.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                    sx={{
                      '.MuiTablePagination-toolbar': { minHeight: '36px' },
                    }} // Reduced height of pagination toolbar
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default Request;
