import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

interface LineChartProps {
  data: any; // You may want to define a more specific type
}

const LineChart: React.FC<LineChartProps> = ({ data }) => {
  const currencySymbol = localStorage.getItem('Currency') || '$'; // Default to '$' if no currency symbol is found

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.raw !== null && context.raw !== undefined) {
              label += `${currencySymbol}${context.raw.toLocaleString()}`; // Using toLocaleString() to add commas
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div className="line-chart-item">
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
