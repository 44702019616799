import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { api } from '../utils/api';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import { toast } from 'react-toastify';
import { Message } from '../utils/interfaces';
import { reverseDate, formatDateForInput } from '../utils/dateUtils';
import { validateEmail } from '../helper/validation';
import Spinner from '../utils/Spinner';
import {
  Button,
  Typography,
  Container,
  Grid,
  TextField,
  Modal,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetApp as DownloadIcon } from '@mui/icons-material';
import { deleteKeyStore } from '../utils/indexedDB';
import { useUserStore } from '../stores/UserStore';
import { useFetchTenants } from '../hooks/tenant.hooks';

function TenantDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const errorMessages = new ErrorMessages();
  const initialTenant = location.state?.tenant;
  const [editable, setEditable] = useState(false);
  const [tenant, setTenant] = useState(initialTenant);
  const [editedTenant, setEditedTenant] = useState(initialTenant);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageSubject, setMessageSubject] = useState(t('reminder_subject'));
  const [messageDescription, setMessageDescription] = useState(
    t('reminder_message')
  );
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { user } = useUserStore();
  const { refetch } = useFetchTenants();

  const handleRefetch = () => {
    refetch()
      .then(() => {
        console.log('Data successfully refetched');
      })
      .catch((error) => {
        console.error('Error refetching tenants:', error);
      });
  };

  useEffect(() => {
    setEditedTenant(tenant);
  }, [tenant]);

  // Function to handle tenant deletion
  const handleDelete = async () => {
    try {
      await api.deleteTenant(tenant.PropertyID, tenant.TenantID);
      await deleteKeyStore('tenants');
      await deleteKeyStore('properties');
      toast.success(t('delete_success'));
      navigate('/tenant');
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    } finally {
      setOpenDeleteModal(false);
    }
  };

  // Function to handle tenant update
  const handleUpdate = async () => {
    const {
      FirstName,
      LastName,
      Unit,
      Complex,
      Email,
      Telephone,
      StartDate,
      EndDate,
      PropertyID,
      TenantID,
    } = editedTenant;

    // Validate email
    if (!validateEmail(Email)) {
      toast.error(t('invalid_email'));
      return;
    }

    // Parse dates
    const startDate = new Date(StartDate);
    const endDate = new Date(EndDate);

    // Check if dates are valid
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      toast.error(t('invalid_date'));
      return;
    }

    // Check if end date is not earlier than start date
    if (endDate < startDate) {
      toast.error(t('end_date_error'));
      return;
    }

    // Convert dates to UTC format (if needed)
    const utcStartDate = new Date(
      Date.UTC(
        startDate.getUTCFullYear(),
        startDate.getUTCMonth(),
        startDate.getUTCDate()
      )
    );
    const utcEndDate = new Date(
      Date.UTC(
        endDate.getUTCFullYear(),
        endDate.getUTCMonth(),
        endDate.getUTCDate()
      )
    );

    const correctedEditedTenant = {
      FirstName,
      LastName,
      Unit,
      Complex,
      Email,
      Telephone,
      StartDate: utcStartDate.toISOString().split('T')[0],
      EndDate: utcEndDate.toISOString().split('T')[0],
      PropertyID,
      TenantID,
      file: null,
    };

    try {
      // Update the tenant via API
      await api.updateTenant(PropertyID, TenantID, correctedEditedTenant);
      await deleteKeyStore('tenants');
      toast.success(t('updated'));

      // Update the tenant state with the new data
      setTenant(correctedEditedTenant);
      setEditedTenant(correctedEditedTenant);
      setEditable(false);

      // Optionally, refetch tenants if needed
      handleRefetch();
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    }
  };

  const handleCancel = () => {
    if (editable) {
      setEditable(false);
      setEditedTenant(tenant);
    } else {
      navigate('/tenant');
    }
  };

  const handleEdit = () => {
    setEditable(true);
  };

  const handleCloseModal = () => {
    setShowMessageModal(false);
    setLoading(false);
  };

  const handleSendMessage = () => {
    setLoading(true);
    const messageData: Message = {
      MessageID: 0,
      Subject: messageSubject,
      Description: messageDescription,
      TenantID: tenant.TenantID,
      FirstName: tenant.FirstName,
      LastName: tenant.LastName,
      Email: tenant.Email,
    };

    api
      .sendMessage(messageData)
      .then(async () => {
        setLoading(false);
        setShowMessageModal(false);
        await deleteKeyStore('messages');
        toast.success(t('message_sent'));
      })
      .catch((error) => {
        setLoading(false);
        ErrorHandler.handleError(error, errorMessages, toast);
      });
  };

  //@ts-ignore
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the field is a date, store it correctly
    if (name === 'StartDate' || name === 'EndDate') {
      // Convert the input date back to UTC format for consistent storage
      const date = new Date(value);
      const utcDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split('T')[0];
      setEditedTenant({ ...editedTenant, [name]: utcDate });
    } else {
      setEditedTenant({ ...editedTenant, [name]: value });
    }
  };

  const handleDownloadPdf = () => {
    api
      .downloadFile(tenant.TenantID)
      .then((response) => {
        if (!response) {
          toast.warning('Empty response received');
          return;
        }

        console.log('Received PDF blob:', response);
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${tenant.FirstName}_${tenant.LastName}_Contract.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        ErrorHandler.handleError(error, errorMessages, toast);
      });
  };

  // Render loading message if tenant details are not available
  if (!tenant) {
    return <Spinner />;
  }

  return (
    <div className="overview-container">
      <main className="tenant-details-content">
        <Container maxWidth="md">
          <Typography variant="h5" gutterBottom>
            {t('tenant_information')}
          </Typography>
          <div
            style={{
              border: '1px solid #ccc',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              background: 'transparent',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <Typography>
                    <strong>{t('first_name')}:</strong> {tenant.FirstName}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography>
                    <strong>{t('last_name')}:</strong> {tenant.LastName}
                  </Typography>
                </Box>
                {editable ? (
                  <>
                    <Box mb={2}>
                      <TextField
                        name="Email"
                        label={t('email')}
                        fullWidth
                        value={editedTenant.Email}
                        onChange={handleInputChange}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="Telephone"
                        label={t('telephone')}
                        fullWidth
                        value={editedTenant.Telephone}
                        onChange={handleInputChange}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="Unit"
                        label={t('unit')}
                        fullWidth
                        value={editedTenant.Unit}
                        onChange={handleInputChange}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="Complex"
                        label={t('complex')}
                        fullWidth
                        value={editedTenant.Complex}
                        onChange={handleInputChange}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="StartDate"
                        label={t('start_date')}
                        fullWidth
                        type="date"
                        value={formatDateForInput(editedTenant.StartDate)}
                        onChange={handleInputChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        name="EndDate"
                        label={t('end_date')}
                        fullWidth
                        type="date"
                        value={formatDateForInput(editedTenant.EndDate)}
                        onChange={handleInputChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box mb={2}>
                      <Typography>
                        <strong>{t('email')}:</strong> {tenant.Email}
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography>
                        <strong>{t('telephone')}:</strong> {tenant.Telephone}
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography>
                        <strong>{t('unit')}:</strong> {tenant.Unit}
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography>
                        <strong>{t('complex')}:</strong> {tenant.Complex}
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography>
                        <strong>{t('start_date')}:</strong>{' '}
                        {reverseDate(tenant.StartDate)}
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography>
                        <strong>{t('end_date')}:</strong>{' '}
                        {reverseDate(tenant.EndDate)}
                      </Typography>
                    </Box>
                  </>
                )}

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleDownloadPdf}
                  startIcon={<DownloadIcon />}
                  style={{ marginBottom: '16px' }}
                >
                  {t('download_contract')}
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                {/* Button group */}
                <div className="button-group">
                  {editable && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleCancel}
                      fullWidth
                      style={{
                        marginBottom: '10px',
                        width: 'calc(100% - 50px)',
                      }}
                    >
                      {t('cancel')}
                    </Button>
                  )}
                  {!editable && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate('/tenant')}
                      fullWidth
                      style={{
                        marginBottom: '10px',
                        width: 'calc(100% - 50px)',
                      }}
                    >
                      {t('back')}
                    </Button>
                  )}

                  {user?.UserRoleType !== 'Caretaker' && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={editable ? handleUpdate : handleEdit}
                        fullWidth
                        style={{
                          marginBottom: '10px',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {editable ? t('update') : t('edit')}
                      </Button>

                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => setOpenDeleteModal(true)}
                        fullWidth
                        style={{
                          marginBottom: '10px',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {t('delete')}
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowMessageModal(true)}
                        fullWidth
                        style={{
                          marginBottom: '10px',
                          width: 'calc(100% - 50px)',
                        }}
                      >
                        {t('send_message')}
                      </Button>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </main>

      {/* Delete confirmation modal */}
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            {t('confirm_deletion')}
          </Typography>
          <Typography gutterBottom>
            {t('confirm_deletion_message')} {tenant.FirstName} {tenant.LastName}
            ?
          </Typography>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenDeleteModal(false)}
            >
              {t('no')}
            </Button>
            <Button variant="contained" color="error" onClick={handleDelete}>
              {t('yes')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Message sending modal */}
      <Modal open={showMessageModal} onClose={() => setShowMessageModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            {t('sending_message')} {tenant.LastName}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            id="message-subject"
            label={t('subject')}
            value={messageSubject}
            onChange={(e) => setMessageSubject(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            id="message-description"
            label={t('description')}
            multiline
            rows={3}
            value={messageDescription}
            onChange={(e) => setMessageDescription(e.target.value)}
          />
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseModal}
            >
              {t('close')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
            >
              {t('send')}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Spinner component */}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <Spinner />
        </Box>
      )}
    </div>
  );
}

export default TenantDetails;
