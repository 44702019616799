import { create } from 'zustand';
import { Room } from '../utils/interfaces';

interface RoomStore {
  rooms: Room[] | null;
  setRooms: (rooms: Room[]) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const useRoomStore = create<RoomStore>((set) => ({
  rooms: null,
  loading: false,

  // Function to set rooms in the store
  setRooms: (rooms: Room[]) => set({ rooms }),

  // Function to set loading state
  setLoading: (loading: boolean) => set({ loading }),
}));
