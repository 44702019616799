import React, { useState, useEffect } from 'react';
import './sass/Main.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Sidebar from './components/Sidebar';
import Profile from './components/Profile';
import Login from './components/Login';
import Overview from './components/Overview';
import Property from './components/Property';
import VerifyEmail from './components/VerifyEmail';
import Notification from './components/Notification';
import Request from './components/Request';
import ResetPassword from './components/ResetPassword';
import Subscription from './components/Subscription';
import Messages from './components/Messages';
import TenantDetails from './components/TenantDetails';
import PropertyDetails from './components/PropertyDetails';
import Tenant from './components/Tenant';
import Plantype from './components/Plantype';
import AboutUs from './components/AboutUs';
import MonthlyAnalysis from './components/MonthlyAnalysis';
import YearlyAnalysis from './components/YearlyAnalysis';
import ProfitAndLoss from './components/ProfitAndLoss';
import Information from './components/Information';
import PageNotFound404 from './components/404';
import ProtectedRoute from './utils/ProtectedRoute';
import PublicRoute from './utils/PublicRoute';
import { Authenticator } from './helper/Authenticator';

const App: React.FC = () => {
  const [isAuth, setAuth] = useState<boolean | null>(false);
  const [isLoading, setLoading] = useState<boolean | null>(true);
  const [userRoleType, setUserRoleType] = useState<string | null | undefined>(
    null
  );
  const [subscriptionType, setSubscriptionType] = useState<
    string | null | undefined
  >(null);
  const [IsSubActive, setIsSubActive] = useState<boolean | undefined>(false);

  // Initialize Authenticator with state setters
  Authenticator.Initialize(
    isAuth,
    isLoading,
    userRoleType,
    subscriptionType,
    IsSubActive,
    setAuth,
    setLoading,
    setUserRoleType,
    setSubscriptionType,
    setIsSubActive
  );

  useEffect(() => {
    // Simulate fetching auth state
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // Get current location using useLocation hook
  const location = useLocation();

  // Determine additional class based on current pathname
  const getPageSpecificClass = () => {
    const { pathname } = location;

    if (pathname === '/overview') {
      return 'overview';
    } else if (pathname === '/profile') {
      return 'profile';
    } else if (pathname === '/property') {
      return 'property';
    } else if (pathname === '/tenant') {
      return 'tenant';
    } else if (pathname === '/notifications') {
      return 'notifications';
    } else if (pathname === '/information') {
      return 'information';
    } else if (pathname === '/requests') {
      return 'requests';
    } else if (pathname === '/plantype') {
      return 'plantype';
    } else if (pathname === '/messages') {
      return 'messages';
    } else if (pathname.startsWith('/tenant/')) {
      return 'tenant-details';
    } else if (pathname.startsWith('/property/')) {
      return 'property-details';
    } else if (pathname === '/monthly-analysis') {
      return 'monthly-analysis';
    } else if (pathname === '/yearly-analysis') {
      return 'yearly-analysis';
    } else if (pathname === '/profit-and-loss') {
      return 'profit-and-loss';
    } else if (pathname === '/about-us') {
      return 'about-us';
    } else {
      return 'page-not-found'; // Default class when no specific match
    }
  };

  return (
    <React.StrictMode>
      <div className="App">
        <AuthProvider>
          {isAuth && <Sidebar />} {/* Conditionally render Sidebar */}
          <div
            className={`main-content ${isAuth ? 'authenticated' : ''} ${getPageSpecificClass()}`}
          >
            <Routes>
              {/* @ts-ignore */}
              <Route element={<PublicRoute isAuth={isAuth} to="/overview" />}>
                <Route path="/" element={<Login />} />
              </Route>
              {/* @ts-ignore */}
              <Route element={<PublicRoute isAuth={isAuth} to="/overview" />}>
                <Route path="/login" element={<Login />} />
              </Route>
              <Route path="/verify-email/:token" element={<VerifyEmail />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/verify-subscription/:token"
                element={<Subscription />}
              />
              <Route path="/about-us" element={<AboutUs />} />
              <Route
                element={<ProtectedRoute accessDenied={!isAuth} to="/login" />}
              >
                <Route path="/overview" element={<Overview />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/property" element={<Property />} />
                <Route path="/tenant" element={<Tenant />} />
                <Route path="/notifications" element={<Notification />} />
                <Route path="/requests" element={<Request />} />
                <Route path="/plantype" element={<Plantype />} />
                <Route path="/messages" element={<Messages />} />
                <Route path="/tenant/:tenantId" element={<TenantDetails />} />
                <Route
                  path="/property/:propertyId"
                  element={<PropertyDetails />}
                />
                <Route path="/monthly-analysis" element={<MonthlyAnalysis />} />
                <Route path="/yearly-analysis" element={<YearlyAnalysis />} />
                <Route path="/profit-and-loss" element={<ProfitAndLoss />} />
                <Route path="/information" element={<Information />} />
              </Route>
              <Route path="*" element={<PageNotFound404 />} />
            </Routes>
          </div>
        </AuthProvider>
      </div>
    </React.StrictMode>
  );
};

export default App;
