import React, { useState, useEffect, ChangeEvent } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { api } from '../utils/api';
import { User } from '../utils/interfaces';
import { toast } from 'react-toastify';
import Spinner from '../utils/Spinner';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import { countries, Country } from '../utils/countries';
import avatars from '../utils/avatars';
import AvatarModal from '../helper/AvatarModal';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Grid,
  Avatar,
  Modal,
  Backdrop,
  Typography,
  TextField,
  Fade,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { clearAllData } from '../utils/indexedDB';
import { useFetchUser, useUpdateUserProfile } from '../hooks/user.hooks';
import { useUserStore } from '../stores/UserStore';
import { deleteKeyStore } from '../utils/indexedDB';

interface CountryDropdownProps {
  countries: Country[];
  value: string;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  countries,
  value,
  onChange,
  disabled,
}) => (
  <FormControl fullWidth>
    <InputLabel>Country</InputLabel>
    <Select
      value={value}
      //@ts-ignore
      onChange={onChange}
      disabled={disabled}
      variant="outlined"
    >
      {countries.map((country) => (
        <MenuItem key={country.currency} value={country.name}>
          {country.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

function Profile() {
  const { t } = useTranslation();
  const [showUserID, setShowUserID] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const errorMessages = new ErrorMessages();

  const {
    data: profile,
    isError: isUserError,
    error: userError,
    isLoading: isUserLoading,
    refetch,
  } = useFetchUser();
  const { setUser } = useUserStore();
  const updateUserProfile = useUpdateUserProfile();
  const [updatedProfile, setUpdatedProfile] = useState(profile);

  useEffect(() => {
    if (isUserLoading) {
      <Spinner />;
      return;
    }
  }, [isUserLoading]);

  useEffect(() => {
    if (isUserError) {
      ErrorHandler.handleError(userError, errorMessages, toast);
    }
  }, [isUserError, userError]);

  const handleRefetch = () => {
    refetch()
      .then(() => {
        console.log('Data successfully refetched');
      })
      .catch((error) => {
        console.error('Error refetching tenants:', error);
      });
  };

  // Set updatedProfile when profile data is fetched
  useEffect(() => {
    if (profile) {
      setUpdatedProfile(profile);
    }
  }, [profile]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setUpdatedProfile((prev) => {
      if (!prev) return;

      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleCountryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedCountry = countries.find(
      (country) => country.name === event.target.value
    );
    if (selectedCountry && updatedProfile) {
      setUpdatedProfile({
        ...updatedProfile,
        Country: selectedCountry.name,
        Currency: selectedCountry.currency,
      });
    }
  };

  // Handle avatar selection
  const handleSelectAvatar = async (avatarUrl: string) => {
    if (!updatedProfile) {
      toast.error('Profile is not defined'); // TODO
      return;
    }

    const updatedUser: Partial<User> = { ...updatedProfile, Avatar: avatarUrl };
    setUpdatedProfile(updatedUser as User);

    try {
      const response = await api.uploadAvatar(updatedUser as User);

      if (response.success) {
        toast.success(t('avatar_upload_success'));
        setUser(updatedUser as User); // Update Zustand store on success
        await deleteKeyStore('profileDetails');
        handleRefetch();
      } else {
        toast.error(t('avatar_upload_fail'));
      }
    } catch (error) {
      toast.error(t('avatar_upload_error'));
      // Revert to previous avatar if upload failed
      setUpdatedProfile(profile); // Restore previous state
    } finally {
      setShowModal(false);
    }
  };

  // Handle profile form submission
  const handleSubmit = () => {
    if (updatedProfile) {
      updateUserProfile.mutate(updatedProfile, {
        onSuccess: () => {
          setIsEditable(false);
          setUser(updatedProfile as User);
          handleRefetch();
        },
        onError: (error) => {
          toast.error(t('profile_update_fail'));
        },
      });
    }
  };

  // Toggle edit mode
  const toggleEdit = () => {
    setIsEditable((prev) => !prev);
  };

  // Cancel edit and reset profile to initial state
  const cancelEdit = () => {
    setIsEditable(false);
  };

  // Handle account deletion
  const handleDeleteAccount = () => {
    if (updatedProfile && updatedProfile.Email) {
      api
        .deleteAccount(updatedProfile.Email)
        .then((response) => {
          toast.success(response);
          localStorage.clear();
          clearAllData()
            .then(() => {
              window.location.href = '/';
            })
            .catch((error) => {
              console.error('Error clearing IndexedDB:', error.message);
              window.location.href = '/';
            });
        })
        .catch((error) => {
          toast.warning(error.message);
        });
      setOpenDeleteModal(false);
    }
  };

  if (isUserLoading) {
    return <Spinner />;
  }

  return (
    <div className="overview-container">
      <main
        className="profile-content"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingTop: '20px',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item md={3}>
              <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                <Avatar
                  className="rounded-circle mt-5"
                  sx={{ width: 150, height: 150, cursor: 'pointer' }}
                  src={
                    updatedProfile?.Avatar ||
                    'https://path/to/default/avatar.jpg'
                  }
                  alt="Profile"
                  onClick={() => setShowModal(true)} // Open modal on click
                />
                <span className="font-weight-bold">
                  {updatedProfile?.Email}
                </span>
                {/* Display UserID */}
                <div className="d-flex align-items-center mt-3">
                  <InputLabel className="labels">{t('unique_id')}</InputLabel>
                  <div className="d-flex align-items-center">
                    <TextField
                      type={showUserID ? 'text' : 'password'}
                      className="form-control"
                      value={updatedProfile?.UserID}
                      //@ts-ignore
                      readOnly
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowUserID((prev) => !prev)}
                              edge="end"
                            >
                              {showUserID ? (
                                <AiOutlineEyeInvisible />
                              ) : (
                                <AiOutlineEye />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ width: '100%' }} // Increase width for Unique ID
                    />
                  </div>
                </div>
                {/* Delete Account*/}
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setOpenDeleteModal(true)}
                  sx={{ mt: 2 }}
                >
                  {t('delete_account')}
                </Button>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center mb3">
                  <h4 className="text-right">{t('profile_settings')}</h4>
                  {isEditable ? (
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={cancelEdit}
                        sx={{ mr: 2 }} // Add margin-right
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{ ml: 2 }} // Add margin-left
                      >
                        {t('save')}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={toggleEdit}
                    >
                      {t('edit')}
                    </Button>
                  )}
                </div>
                <TextField
                  label={t('fullname')}
                  variant="outlined"
                  fullWidth
                  placeholder={t('enter_full_name')}
                  name="Fullname"
                  value={updatedProfile?.Fullname}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  sx={{ mb: 3, mt: 3 }}
                />
                <TextField
                  label={t('phone_number')}
                  variant="outlined"
                  fullWidth
                  placeholder={t('enter_phone_number')}
                  name="Phone"
                  value={updatedProfile?.Phone}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t('address')}
                  variant="outlined"
                  fullWidth
                  placeholder={t('enter_address')}
                  name="Address"
                  value={updatedProfile?.Address}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t('city')}
                  variant="outlined"
                  fullWidth
                  placeholder={t('enter_city')}
                  name="City"
                  value={updatedProfile?.City}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  sx={{ mb: 3 }}
                />
                <TextField
                  label={t('postal_code')}
                  variant="outlined"
                  fullWidth
                  placeholder={t('enter_postal_code')}
                  name="PostalCode"
                  value={updatedProfile?.PostalCode}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                  sx={{ mb: 3 }}
                />
                <CountryDropdown
                  countries={countries}
                  value={updatedProfile?.Country || ''}
                  onChange={handleCountryChange}
                  disabled={!isEditable}
                />
              </div>
            </Grid>
          </Grid>
          {showModal && (
            <AvatarModal
              avatars={avatars}
              onSelect={handleSelectAvatar}
              onClose={() => setShowModal(false)}
            />
          )}
        </Container>
      </main>
      {/* Modal for delete confirmation */}
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDeleteModal}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh', // Center vertically
            }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                maxWidth: '400px',
                width: '100%',
                textAlign: 'center',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '5px',
              }}
            >
              <Typography variant="h6" gutterBottom>
                {t('delete_account_confirmation')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDeleteAccount}
                style={{ marginRight: '10px' }}
              >
                {t('yes')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenDeleteModal(false)}
              >
                {t('no')}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default Profile;
