import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../utils/Spinner';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  IconButton,
  TablePagination,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { api } from '../utils/api';
import { AccessRequest } from '../utils/interfaces';
import { useTranslation } from 'react-i18next';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';

function Notification() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState<AccessRequest[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const errorMessages = new ErrorMessages();

  const fetchrequests = () => {
    // Function to fetch access requests from API
    setLoading(true);
    api
      .getAccessRequests()
      .then((response) => {
        setRequests(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchrequests();
  }, []);

  const formatDate = (dateString: string) => {
    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`; // Return formatted date
  };

  const handleStatusChange = (request: AccessRequest, checked: boolean) => {
    const updatedRequest = {
      ...request,
      Status: checked ? 'Active' : 'Revoked', // Update status based on checkbox state
    };

    api
      .updateAccessRequest(updatedRequest)
      .then((response) => {
        toast.success(t(response.message));
        fetchrequests();
      })
      .catch((error) => {
        ErrorHandler.handleError(error, errorMessages, toast);
      });
  };

  const handleDelete = (requestID: number) => {
    api
      .deleteAccessRequest(requestID)
      .then((response) => {
        toast.success(t(response.message));
        fetchrequests(); // Refresh the requests after successful deletion
      })
      .catch((error) => {
        ErrorHandler.handleError(error, errorMessages, toast);
      });
  };

  const renderStatusIcon = (status: string) => {
    const color =
      status === 'Active'
        ? 'green'
        : status === 'Pending'
          ? 'orange'
          : status === 'Revoked'
            ? 'red'
            : 'transparent';

    return (
      <div
        style={{
          width: '15px',
          height: '15px',
          borderRadius: '50%',
          backgroundColor: color,
          display: 'inline-block',
        }}
      ></div>
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="overview-container">
      <main className="notification-content">
        {/* Table displaying messages */}
        <div
          className="notification-table-container"
          style={{ overflowY: 'auto' }}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Table className="table table-bordered">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#d5deee' }}>
                      {t('req_id')}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#d5deee' }}>
                      {t('name')}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#d5deee' }}>
                      {t('email')}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#d5deee' }}>
                      {t('role')}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#d5deee' }}>
                      {t('building_name')}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#d5deee' }}>
                      {t('request_date')}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#d5deee' }}>
                      {t('status')}
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#d5deee' }}>
                      {t('actions')}
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* Table body showing notifications */}
                <TableBody>
                  {requests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((req) => (
                      <TableRow key={req.RequestID}>
                        <TableCell>{req.RequestID}</TableCell>
                        <TableCell>{req.RequesterName}</TableCell>
                        <TableCell>{req.RequesterEmail}</TableCell>
                        <TableCell>
                          {t(req.RequesterRole ? req.RequesterRole : '')}
                        </TableCell>
                        <TableCell>{req.PropertyName}</TableCell>
                        {/* @ts-ignore */}
                        <TableCell>{formatDate(req.RequestDate)}</TableCell>
                        <TableCell>{renderStatusIcon(req.Status)}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={req.Status === 'Active'}
                            onChange={(event) =>
                              handleStatusChange(req, event.target.checked)
                            }
                          />
                          <IconButton
                            //@ts-ignore
                            onClick={() => handleDelete(req.RequestID)}
                            color="secondary"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              {/* Pagination controls */}
              <div
                style={{
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: '#fff',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '4px', // Reduced padding to decrease height
                  zIndex: 1000,
                }}
              >
                <Typography variant="body2">
                  {t('page_of', {
                    page: page + 1,
                    totalPages: Math.ceil(requests.length / rowsPerPage),
                  })}
                </Typography>
                <TablePagination
                  component="div"
                  count={requests.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                  sx={{ '.MuiTablePagination-toolbar': { minHeight: '36px' } }} // Reduced height of pagination toolbar
                />
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
}

export default Notification;
