import React from 'react';

const PageNotFound404: React.FC = () => {
  return (
    <div className="error404">
      <div className="container">
        <h1>404: Page not found</h1>
      </div>
    </div>
  );
};

export default PageNotFound404;
