import { create } from 'zustand';
import { Tenant } from '../utils/interfaces';

interface TenantStore {
  tenants: Tenant[] | null;
  setTenants: (tenants: Tenant[]) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const useTenantStore = create<TenantStore>((set) => ({
  tenants: null,
  loading: false,

  // Function to set tenants in the store
  setTenants: (tenants: Tenant[]) => set({ tenants }),

  // Function to set loading state
  setLoading: (loading: boolean) => set({ loading }),
}));
