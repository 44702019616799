import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Tenant } from '../utils/interfaces';
import { useFetchRentalIncome } from '../hooks/analysis.hooks';
import { toast } from 'react-toastify';
import Spinner from '../utils/Spinner';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';

function MonthlyAnalysis() {
  const { t } = useTranslation();
  const [data, setData] = useState<any>(null);
  const [currentMonthName, setCurrentMonthName] = useState<string>('');
  const [totalRentedRooms, setTotalRentedRooms] = useState<number>(0);
  const [percentageRented, setPercentageRented] = useState<number>(0);
  const [averageCollection, setAverageCollection] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const errorMessages = new ErrorMessages();

  const currencySymbol = localStorage.getItem('Currency') || '$'; // Retrieve currency symbol

  const {
    data: rentalIncome,
    isLoading: isRentalIncomeLoading,
    isError: isRentalIncomeError,
    error: rentalIncomeError,
  } = useFetchRentalIncome();

  useEffect(() => {
    if (isRentalIncomeLoading) {
      setLoading(true);
    }

    if (isRentalIncomeError) {
      ErrorHandler.handleError(rentalIncomeError, errorMessages, toast);
      setLoading(false);
      return;
    }

    if (rentalIncome && rentalIncome.Tenants) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      const monthsShort = t('monthsShort', { returnObjects: true }) as string[];

      // Calculate number of days in the current month
      const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

      // Generate labels for each day of the current month
      const labels = Array.from(
        { length: daysInMonth },
        (_, i) => `${('0' + (i + 1)).slice(-2)} ${monthsShort[currentMonth]}`
      );

      const currentMonthIndex = currentDate.getMonth() + 1;
      setCurrentMonthName(
        t('currentMonth', {
          currentMonthName: t(`months.${currentMonthIndex}`),
          currentYear,
        })
      );

      const tenants: Tenant[] = rentalIncome.Tenants; // Get tenants from the response

      // Prepare monthly payment data
      const {
        data: monthlyPayments,
        totalCollectedRent,
        totalRentedRooms,
      } = prepareMonthlyPaymentData(tenants, daysInMonth, currencySymbol);

      // Map monthly payments to daily payments and no payment indicators
      const dailyPayments = monthlyPayments.map((payment) => ({
        day:
          payment !== `${currencySymbol}0.00`
            ? parseFloat(payment.substring(1))
            : null,
        noPayment: payment === `${currencySymbol}0.00` ? 1 : 0,
      }));

      const totalRooms = rentalIncome.TotalRooms;
      const avgRoomPrice = totalCollectedRent / totalRentedRooms;

      // Calculate percentage of rented rooms
      const rentedRoomsPercentage = (totalRentedRooms / totalRooms) * 100;

      // Set state with the prepared data
      if (labels && dailyPayments) {
        setData({
          labels: labels,
          datasets: [
            {
              label: t('paymentsForMonth', {
                month: monthsShort[currentMonth],
              }),
              backgroundColor: '#82ca9d',
              data: dailyPayments.map((dp) => dp.day),
            },
            {
              label: t('no_payments'),
              backgroundColor: '#ffcccb',
              data: dailyPayments.map((dp) => dp.noPayment),
            },
          ],
        });
      }

      setTotalRentedRooms(totalRentedRooms);
      setPercentageRented(rentedRoomsPercentage);
      setAverageCollection(avgRoomPrice);
      setLoading(false); // Update loading state
    }
  }, [
    rentalIncome,
    isRentalIncomeLoading,
    isRentalIncomeError,
    rentalIncomeError,
    t,
    currencySymbol,
  ]);

  // Function to prepare monthly payment data
  const prepareMonthlyPaymentData = (
    tenants: Tenant[],
    daysInMonth: number,
    currencySymbol: string
  ): {
    data: string[];
    totalCollectedRent: number;
    totalRentedRooms: number;
  } => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    // Initialize data array with zeros for each day of the current month
    const data = new Array(daysInMonth).fill('0');
    let totalCollectedRent = 0;
    let totalRentedRooms = 0;

    tenants.forEach((tenant) => {
      const startDate = new Date(tenant.StartDate);
      const endDate = new Date(tenant.EndDate);
      const yearlyRoomPrice =
        tenant.RoomPrice !== undefined ? tenant.RoomPrice : 0;
      const monthlyRoomPrice = yearlyRoomPrice / 12;
      const dailyRoomPrice = monthlyRoomPrice / daysInMonth; // More precise daily price

      // Calculate payment for each day of the current month
      for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = new Date(currentYear, currentMonth, day);
        if (currentDate >= startDate && currentDate <= endDate) {
          const payment = dailyRoomPrice;
          const currentIndex = day - 1;
          const currentValue = parseFloat(data[currentIndex]);
          const newValue = (currentValue + payment).toFixed(2);
          data[currentIndex] = newValue;
          totalCollectedRent += payment; // Accumulate total collected rent
        }
      }

      // Increment rented rooms count if the tenant is active in the current month
      if (
        (startDate.getFullYear() < currentYear ||
          (startDate.getFullYear() === currentYear &&
            startDate.getMonth() <= currentMonth)) &&
        (endDate.getFullYear() > currentYear ||
          (endDate.getFullYear() === currentYear &&
            endDate.getMonth() >= currentMonth))
      ) {
        totalRentedRooms++;
      }
    });

    // Add currency symbol to each value
    const formattedData = data.map((value) => `${currencySymbol}${value}`);

    return { data: formattedData, totalCollectedRent, totalRentedRooms };
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="overview-container">
      <main className="monthlyAnalysis-content">
        <div className="container">
          <h2>{currentMonthName}</h2>
          {/* Display current month and year */}
          <div className="rankings">
            <h3>{t('rankings')}</h3>
            <div style={{ height: '300px', width: '800px' }}>
              {data && data.labels ? (
                <Bar
                  data={data}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      x: { stacked: true },
                      y: { stacked: true },
                    },
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (tooltipItem) {
                            return `${tooltipItem.dataset.label}: ${currencySymbol}${tooltipItem.raw}`;
                          },
                        },
                      },
                    },
                  }}
                />
              ) : (
                <p>{t('noData')}</p>
              )}
            </div>
          </div>
          <div className="summary" style={{ width: '600px' }}>
            {' '}
            {/* Adjust width as needed */}
            {data && data.labels ? (
              <>
                <div className="summary-item" style={{ width: '100%' }}>
                  <h3>{t('rentedRooms')}</h3>
                  <p className="negative-change">▼ {totalRentedRooms}</p>
                </div>
                <div className="summary-item" style={{ width: '100%' }}>
                  <h3>{t('rentsPercentage')}</h3>
                  <p>{percentageRented.toFixed(2)}%</p>
                </div>
                <div className="summary-item" style={{ width: '100%' }}>
                  <h3>{t('averageCollection')}</h3>
                  <p>{`${currencySymbol} ${averageCollection.toFixed(2)}`}</p>
                </div>
              </>
            ) : (
              <p>{t('loadingSummary')}</p>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default MonthlyAnalysis;
