import { useQuery } from '@tanstack/react-query';
import { api } from '../utils/api';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { Property } from '../utils/interfaces';
import { usePropertyStore } from './../stores/PropertyStore';

// Fetch properties from API
const fetchPropertiesFromApi = async (): Promise<Property[]> => {
  const response = await api.getPropertiesByUser();
  const sortedProperties = response.sort((a: Property, b: Property) =>
    a.PropertyName.localeCompare(b.PropertyName)
  ); // Sort alphabetically by 'PropertyName'

  // Encrypt and save the properties data to IndexedDB
  const encryptedData = encryptData(JSON.stringify(sortedProperties));
  await saveData('properties', encryptedData);

  return sortedProperties;
};

// Load properties from IndexedDB
const loadPropertiesFromIndexedDB = async (): Promise<Property[] | null> => {
  const savedProperties = await getData('properties');

  if (savedProperties && savedProperties.data.length > 0) {
    const decryptedData = decryptData(savedProperties.data);
    return JSON.parse(decryptedData);
  } else {
    return null;
  }
};

// React Query hook to fetch properties
export const useFetchProperties = () => {
  const { setProperties } = usePropertyStore();

  return useQuery<Property[]>({
    queryKey: ['properties'],
    queryFn: async () => {
      const propertiesFromDB = await loadPropertiesFromIndexedDB();

      if (propertiesFromDB) {
        setProperties(propertiesFromDB);
        return propertiesFromDB; // Return properties from IndexedDB
      } else {
        const propertiesFromApi = await fetchPropertiesFromApi(); // Fetch from API if not in IndexedDB
        setProperties(propertiesFromApi);
        return propertiesFromApi;
      }
    },
    refetchOnWindowFocus: false, // Disable refetching when window regains focus
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
  });
};
