import { useQuery } from '@tanstack/react-query';
import { api } from '../utils/api';
import {
  saveData,
  getData,
  decryptData,
  encryptData,
} from '../utils/indexedDB';
import { useRoomStore } from '../stores/RoomStore';

// Fetch rooms from API
const fetchRoomsFromApi = async (propertyId: string): Promise<any[]> => {
  const response = await api.getRoomsByID(propertyId);

  // Encrypt and save the rooms data to IndexedDB
  const encryptedData = encryptData(JSON.stringify(response));
  await saveData(`rooms_${propertyId}`, encryptedData);

  return response;
};

// Load rooms from IndexedDB
const loadRoomsFromIndexedDB = async (
  propertyId: string
): Promise<any[] | null> => {
  const savedRooms = await getData(`rooms_${propertyId}`);

  if (savedRooms && savedRooms.data.length > 0) {
    const decryptedData = decryptData(savedRooms.data);
    return JSON.parse(decryptedData);
  } else {
    return null; // Return null if no saved data
  }
};

// React Query hook to fetch rooms
export const useFetchRooms = (propertyId: string) => {
  const { setRooms } = useRoomStore();

  return useQuery<any[]>({
    queryKey: ['rooms', propertyId],
    queryFn: async () => {
      const roomsFromDB = await loadRoomsFromIndexedDB(propertyId);

      if (roomsFromDB) {
        setRooms(roomsFromDB);
        return roomsFromDB;
      } else {
        const roomsFromApi = await fetchRoomsFromApi(propertyId);
        setRooms(roomsFromApi);
        return roomsFromApi;
      }
    },
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000),
  });
};
