import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Button,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

// @ts-ignore
const ExampleTemplate = ({ closeModal }) => {
  const { t } = useTranslation();

  const csvData = [
    [
      t('property_id'),
      t('first_name'),
      t('last_name'),
      t('unit'),
      t('complex'),
      t('email'),
      t('telephone'),
      t('start_date'),
      t('end_date'),
    ],
    [
      '5f8da1a6-389c-11ef-9646-27be4729f658',
      'John',
      'Doe',
      'A1',
      'Complex 1',
      'john.doe@example.com',
      '1234567890',
      '2023-01-01',
      '2023-12-31',
    ],
  ];

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {t('uploaded_sheet_message')}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label={t('example_template')}>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>
                {t('property_id')}
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                {t('first_name')}
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                {t('last_name')}
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{t('unit')}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                {t('complex')}
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>{t('email')}</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                {t('telephone')}
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                {t('start_date')}
              </TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>
                {t('end_date')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>5f8da1a6-389c-11ef-9646-27be4729f658</TableCell>
              <TableCell>John</TableCell>
              <TableCell>Doe</TableCell>
              <TableCell>A1</TableCell>
              <TableCell>Complex 1</TableCell>
              <TableCell>john.doe@example.com</TableCell>
              <TableCell>1234567890</TableCell>
              <TableCell>2023-01-01</TableCell>
              <TableCell>2023-12-31</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button variant="contained" color="secondary" onClick={closeModal}>
          {t('close')}
        </Button>
        <CSVLink data={csvData} filename="example.csv">
          <Button variant="contained" color="primary">
            {t('download_csv')}
          </Button>
        </CSVLink>
      </div>
    </div>
  );
};

export default ExampleTemplate;
