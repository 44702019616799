import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { api } from '../utils/api';
import { validatePassword } from '../helper/validation';
import { ApiResponse, Token } from '../utils/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Alert,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

interface ChangePasswordParams {
  Email: string;
  NewPassword: string;
}

function ResetPassword() {
  const { t } = useTranslation();
  const { token } = useParams<Token>();
  const [resetEmailStatus, setResetEmailStatus] =
    useState<string>('Verifying...');
  const [userEmail, setUserEmail] = useState<string>('');
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
  const [errorMsgRegister, setErrorMsgRegister] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    if (token) {
      // Checking if token exists
      api
        .verifyResetLink(token)
        .then((response: ApiResponse) => {
          if (response.success) {
            setIsSuccess(true);
            setResetEmailStatus('Reset Password Successfully');
            // @ts-ignore
            setUserEmail(response.success.userEmail);
          } else {
            setIsSuccess(false);
            setResetEmailStatus('Verification Failed');
          }
        })
        .catch(() => {
          setIsSuccess(false);
          setResetEmailStatus('Reset Password Verification Failed');
        });
    } else {
      console.error('Token is undefined');
      setIsSuccess(false);
    }
  }, [token]);

  const handleChangePassword = (data: any): void => {
    const { newPassword, confirmPassword } = data;
    const passwordErrorKey = validatePassword(newPassword);
    if (passwordErrorKey) {
      setErrorMsgRegister(t(passwordErrorKey));
      return;
    } else if (newPassword !== confirmPassword) {
      setErrorMsgRegister(t('passwords_do_not_match'));
      return;
    }

    api
      .changePassword({
        Email: userEmail,
        NewPassword: newPassword,
      } as ChangePasswordParams)
      .then(() => {
        setPasswordChanged(true);
        setResetEmailStatus('Password changed successfully');
      })
      .catch((error: Error) => {
        console.error('Error changing password:', error);
      });
  };

  const toggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  // Rendering password changed success message if passwordChanged state is true
  if (passwordChanged) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <div className="success-message">
          <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
          <p>{resetEmailStatus}</p>
        </div>
      </Container>
    );
  }

  // Rendering reset password form and verification status
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Reset Password
      </Typography>
      {isSuccess ? (
        // Rendering password change form if reset email verification is successful
        <Box
          component="form"
          onSubmit={handleSubmit(handleChangePassword)}
          sx={{ mt: 2, width: '100%', maxWidth: '400px' }}
        >
          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type={showNewPassword ? 'text' : 'password'}
                label="New Password"
                margin="normal"
                sx={{ height: '50px' }}
                InputProps={{
                  sx: { height: '50px' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleNewPasswordVisibility}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                label="Confirm Password"
                margin="normal"
                sx={{ height: '50px' }}
                InputProps={{
                  sx: { height: '50px' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {errorMsgRegister && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMsgRegister}
            </Alert>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Change Password
          </Button>
        </Box>
      ) : (
        // Displaying verification failed message if reset email verification fails
        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
          <div className="failure-message">
            <FontAwesomeIcon icon={faTimesCircle} className="failure-icon" />
            <p>Verification Failed</p>
          </div>
        </Typography>
      )}
    </Container>
  );
}

export default ResetPassword;
