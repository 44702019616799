import i18n from '../i18n';
import { CustomError } from '../utils/interfaces';

interface ErrorMessage {
  code: number;
  key: string;
  context?: string;
}

class ErrorMessages {
  private errors: ErrorMessage[];

  constructor() {
    this.errors = [
      { code: 200, context: 'updated', key: 'errors.exceeded_limit' },
      { code: 400, key: 'errors.invalid' },
      { code: 400, context: 'limit', key: 'errors.exceeded_limit' },
      {
        code: 400,
        context: 'activeRequest',
        key: 'errors.request.activeRequest',
      },
      {
        code: 400,
        context: 'pendingRequest',
        key: 'errors.request.pendingRequest',
      },
      { code: 400, context: 'revoked', key: 'errors.request.revoked' },
      { code: 400, context: 'duplicate', key: 'errors.room_duplicate' },
      { code: 401, key: 'errors.forbidden' },
      { code: 403, context: 'unauthorized', key: 'errors.unauthorized_access' },
      { code: 403, context: 'occupied', key: 'errors.occupied' },
      { code: 404, context: 'notfound', key: 'errors.not_found.not_found' },
      { code: 404, context: 'user', key: 'errors.not_found.user' },
      { code: 404, context: 'property', key: 'errors.not_found.property' },
      { code: 404, context: 'tenant', key: 'errors.not_found.tenant' },
      { code: 404, context: 'room', key: 'errors.not_found.room' },
      {
        code: 404,
        context: 'subscription',
        key: 'errors.not_found.subscription',
      },
      { code: 403, context: 'promocode', key: 'errors.not_found.promocode' },
      { code: 404, context: 'filedata', key: 'errors.not_found.filedata' },
      { code: 500, key: 'errors.internal' },
      // add more error codes and corresponding translation keys
    ].sort((a, b) => a.code - b.code); // Sort by error code in ascending order
  }

  getErrorMessage(code: number, context?: string, statusText?: string): string {
    const error = this.errors.find((err) => {
      // If context is provided, match both code and context
      if (context) {
        return err.code === code && err.context === context;
      }
      // If context is not provided, check if statusText matches any context (in lowercase)
      if (!context && statusText) {
        const normalizedStatusText = statusText
          .toLowerCase()
          .replace(/\s+/g, ''); // Normalize statusText
        return (
          err.code === code &&
          (err.context === normalizedStatusText || !err.context)
        );
      }
      // Otherwise, match only by code (no context needed)
      return err.code === code && !err.context;
    });

    // Return the error key translation if found, otherwise fallback to unknown error
    return error ? i18n.t(error.key) : i18n.t('errors.unknown_error');
  }
}

class ErrorHandler {
  static handleError(
    error: any,
    errorMessages: ErrorMessages,
    toast: any
  ): void {
    const customError = error as CustomError;
    const errorMessage = errorMessages.getErrorMessage(
      customError.statusCode,
      customError.context,
      customError.statusText
    );
    toast.error(errorMessage);
  }
}

export { ErrorMessages, ErrorHandler };
