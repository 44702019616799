import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Room } from '../utils/interfaces';
import { api } from '../utils/api';
import Spinner from '../utils/Spinner';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
  Container,
  Grid,
  TextField,
  Box,
  Modal,
  Backdrop,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { deleteKeyStore } from '../utils/indexedDB';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import { useRoomStore } from '../stores/RoomStore';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useFetchProperties } from '../hooks/property.hooks';
import { useFetchRooms } from '../hooks/room.hooks';
import { useFetchUser } from '../hooks/user.hooks';

function PropertyDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const errorMessages = new ErrorMessages();
  const property = location.state?.property;
  const [propertyEditable, setPropertyEditable] = useState(false);
  const [roomEditable, setRoomEditable] = useState(false);
  const [editedProperty, setEditedProperty] = useState(property);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [newRoomCount, setNewRoomCount] = useState(0);
  const [showPropertyID, setShowPropertyID] = useState(false);
  const currencySymbol = localStorage.getItem('Currency') || '$';

  const {
    data: user,
    isError: isUserError,
    error: userError,
    isLoading: isUserLoading,
  } = useFetchUser();
  const {
    data: propertiesData,
    isError: isPropertyError,
    error: propertyError,
    isLoading: isPropertyLoading,
    refetch: refetchProperties,
  } = useFetchProperties();
  const {
    data: room,
    isError: isRoomError,
    error: roomError,
    isLoading: isRoomsLoading,
    refetch: refetchRooms,
  } = useFetchRooms(property.PropertyID);
  const { rooms } = useRoomStore();

  useEffect(() => {
    if (isUserLoading || isPropertyLoading || isRoomsLoading) {
      <Spinner />;
      return;
    }
  }, [isUserLoading, isPropertyLoading, isRoomsLoading]);

  useEffect(() => {
    if (isUserError) {
      ErrorHandler.handleError(userError, errorMessages, toast);
    }
    if (isPropertyError) {
      ErrorHandler.handleError(propertyError, errorMessages, toast);
    }
    if (isRoomError) {
      ErrorHandler.handleError(roomError, errorMessages, toast);
    }
  }, [
    isUserError,
    userError,
    isPropertyError,
    propertyError,
    isRoomError,
    roomError,
    errorMessages,
    toast,
  ]);

  useEffect(() => {
    if (propertiesData) {
      const updatedProperty = propertiesData.find(
        (p) => p.PropertyID === editedProperty.PropertyID
      );
      if (updatedProperty) {
        setEditedProperty(updatedProperty);
      }
    }
  }, [propertiesData, editedProperty.PropertyID]);

  const refetchPropertyDetails = async () => {
    await refetchProperties();
    await refetchRooms();
  };

  const handleDelete = async () => {
    try {
      await api.deleteProperty(property.PropertyID);
      await deleteKeyStore('properties');
      await deleteKeyStore(`rooms_${property.PropertyID}`);

      toast.success(t('delete_success'));
      navigate('/property');
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    } finally {
      setOpenDeleteModal(false);
    }
  };

  const handleUpdate = async () => {
    try {
      await api.updateProperty(editedProperty.PropertyID, editedProperty);
      toast.success(t('updated'));
      await deleteKeyStore('properties');
      await deleteKeyStore(`rooms_${property.PropertyID}`);
      refetchPropertyDetails();
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    } finally {
      setPropertyEditable(false);
    }
  };

  const handleCancel = () => {
    if (propertyEditable) {
      setPropertyEditable(false);
      setEditedProperty(property);
    } else {
      navigate('/property');
    }
  };

  const handleEditProperty = () => {
    setPropertyEditable(true);
  };

  const handleRoomCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const count = parseInt(e.target.value, 10);
    if (!isNaN(count)) {
      setNewRoomCount(count);
    }
  };

  const handleAddRoom = async () => {
    const roomData: Room = {
      PropertyID: editedProperty.PropertyID,
      Price: editedProperty.Price,
    };

    if (newRoomCount <= 0 || !Number.isInteger(newRoomCount)) {
      toast.warning(t('invalid'));
      return;
    }

    const roomCreationPromises = [];
    for (let i = 0; i < newRoomCount; i++) {
      roomCreationPromises.push(
        api.createRoom(roomData).catch((error) => {
          toast.warning(error.message);
        })
      );
    }

    try {
      await Promise.all(roomCreationPromises);
      toast.success(t('updated'));
      handleRoomCancel();
      await deleteKeyStore('properties');
      await deleteKeyStore(`rooms_${property.PropertyID}`);
      refetchPropertyDetails();
    } catch (error) {
      ErrorHandler.handleError(error, errorMessages, toast);
    }
  };

  const handleRoomCancel = () => {
    setNewRoomCount(0);
    setRoomEditable(false);
  };

  if (!property) {
    return <div>Loading property details...</div>;
  }

  const isHostel = editedProperty.PropertyType === 'Hostel';

  return (
    <Container maxWidth="lg">
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('propertyInformation')}
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <Typography>
                  <strong>{t('property_id')}:</strong>{' '}
                  {showPropertyID ? property.PropertyID : '********'}
                  <Button onClick={() => setShowPropertyID(!showPropertyID)}>
                    {showPropertyID ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </Button>
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography>
                  {propertyEditable ? (
                    <TextField
                      name="PropertyName"
                      label="Property Name"
                      fullWidth
                      key={editedProperty.PropertyID}
                      value={editedProperty.PropertyName}
                      onChange={(e) =>
                        setEditedProperty({
                          ...editedProperty,
                          PropertyName: e.target.value,
                        })
                      }
                    />
                  ) : (
                    property.PropertyName
                  )}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography>
                  {propertyEditable ? (
                    <TextField
                      name="Price"
                      label="Price"
                      fullWidth
                      key={editedProperty.PropertyID} // Use PropertyID as key to force re-render when it changes
                      value={editedProperty.Price}
                      onChange={(e) =>
                        setEditedProperty({
                          ...editedProperty,
                          Price: e.target.value,
                        })
                      }
                    />
                  ) : (
                    `${currencySymbol}${editedProperty.Price?.toLocaleString() || ''}`
                  )}
                </Typography>
              </Box>
              {!isHostel && (
                <>
                  <Box mb={2}>
                    <Typography>
                      <strong>{t('bathrooms')}:</strong>{' '}
                      {property.NumberOfBathrooms}
                    </Typography>
                  </Box>
                  <Box mb={2}>
                    <Typography>
                      <strong>{t('bedrooms')}:</strong>{' '}
                      {property.NumberOfBedrooms}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" flexDirection="column">
                {propertyEditable ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancel}
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    {t('cancel')}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/property')}
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    {t('back')}
                  </Button>
                )}
                {user?.UserRoleType !== 'Caretaker' && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={
                        propertyEditable ? handleUpdate : handleEditProperty
                      }
                      fullWidth
                      sx={{ mb: 2 }}
                    >
                      {t(propertyEditable ? 'update' : 'edit')}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setOpenDeleteModal(true)}
                      fullWidth
                      sx={{ mb: 2 }}
                    >
                      {t('delete')}
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Box mt={4}>
          {!roomEditable && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setRoomEditable(true)}
              sx={{ mb: 2 }}
            >
              {t('add_unit')}
            </Button>
          )}

          {roomEditable && (
            <Box>
              <TextField
                type="number"
                label={t('units')}
                value={newRoomCount}
                onChange={handleRoomCountChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <Box display="flex" justifyContent="flex-start">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddRoom}
                  disabled={newRoomCount <= 0}
                  sx={{ mr: 2 }}
                >
                  {t('add')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleRoomCancel}
                >
                  {t('cancel')}
                </Button>
              </Box>
            </Box>
          )}

          {/* Room Table */}
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Typography variant="h5" gutterBottom>
              {t('unitInfo')}
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('numberOfRooms')}</TableCell>
                  <TableCell>{t('occupiedRooms')}</TableCell>
                  <TableCell>{t('availableRooms')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {/* @ts-ignore */}
                    {rooms?.totalRoomsCount}
                  </TableCell>
                  <TableCell>
                    {/* @ts-ignore */}
                    {rooms?.occupiedRoomsCount}
                  </TableCell>
                  <TableCell>
                    {/* @ts-ignore */}
                    {rooms?.availableRoomsCount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Delete Confirmation Modal */}
        <Modal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDeleteModal}>
            <Box sx={{ ...modalStyle, p: 4 }}>
              <Typography variant="h6" gutterBottom>
                {t('confirm_delete_property')}
              </Typography>
              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                fullWidth
                sx={{ mb: 2 }}
              >
                {t('delete')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenDeleteModal(false)}
                fullWidth
              >
                {t('cancel')}
              </Button>
            </Box>
          </Fade>
        </Modal>
      </Box>
    </Container>
  );
}

export default PropertyDetails;

// Styles for the Modal
const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
