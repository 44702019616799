import { create } from 'zustand';

interface ConversionRateStore {
  conversionRate: string;
  setConversionRate: (rate: string) => void;
}

export const useConversionRateStore = create<ConversionRateStore>((set) => ({
  conversionRate: '',
  setConversionRate: (rate: string) => set({ conversionRate: rate }),
}));
